import React from 'react'
import { ErrorMessage, useField } from 'formik'
import classNames from 'classnames'
import { _t } from '@locales/index'

interface Props extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  name: string
  id: string
  showCharacterLimit?: boolean
  textClass?: string
  disabled?: boolean
  label?: string
  hideErrorMessage?: boolean
  fontWeightClass?: string
  labelClass?: string
}

const TextareaField: React.FC<Props> = ({
  className,
  textClass,
  hideErrorMessage,
  fontWeightClass,
  labelClass,
  label,
  maxLength,
  showCharacterLimit,
  ...props
}) => {
  const [field, meta] = useField(props)
  const error = meta.touched && meta.error

  return (
    <div className='w-full flex flex-col'>
      {label && (
        <label
          htmlFor={props.id}
          className={classNames('body2 capitalize', labelClass)}
        >
          {label}
        </label>
      )}
      <textarea
        autoComplete='off'
        className={classNames(
          'bg-theme mt-2 flex-1 w-full resize-none px-4 py-2.5 border placeholder:text-custom-gray-4 placeholder:no-underline focus:ring-0 outline-none rounded disabled:bg-custom-gray-5 disabled:border-none',
          error &&
            'border-custom-error-red bg-custom-red bg-opacity-10 focus:border-custom-error-red',
          className,
          textClass || 'body2',
          fontWeightClass || 'font-light',
        )}
        rows={4}
        maxLength={maxLength}
        {...field}
        {...props}
      />
      <div className='flex flex-row justify-between items-center'>
        {!hideErrorMessage && (
          <ErrorMessage
            name={props.name}
            component={'p'}
            className='text-custom-error-red caption capitalize mr-auto mt-2'
          />
        )}
        {showCharacterLimit && maxLength && (
          <p className={'body-3 text-custom-gray-3 ml-auto dynamic-align mt-2'}>
            {_t('charactersLeft', {
              numCharacters: maxLength - meta.value.length,
            })}
          </p>
        )}
      </div>
    </div>
  )
}

export default TextareaField
