import { useQuery, UseQueryOptions } from 'react-query'
import { ServerStateKeysEnum } from '@enums/serverState'
import { mainApiService } from '@services/api/apiService'
import { ApiKeyNameEnum } from '@enums/api'
import { AxiosError } from 'axios'
import { TypeMemberRequest } from '@customTypes/memberRequest'

/*****************************************
 Member Request
 ****************************************/

type TypeGetMemberRequestOptions = UseQueryOptions<
  TypeMemberRequest,
  AxiosError,
  TypeMemberRequest,
  any
>

const getMemberRequest = async (token: string): Promise<TypeMemberRequest> => {
  try {
    const response = await mainApiService.get(
      ApiKeyNameEnum.member_request,
      token,
    )
    return response.data
  } catch (err) {
    throw err
  }
}

export const useGetMemberRequest = (
  token: string,
  options?: TypeGetMemberRequestOptions,
) => {
  return useQuery(
    [ServerStateKeysEnum.MemberRequest, token],
    () => getMemberRequest(token),
    {
      ...options,
    },
  )
}
