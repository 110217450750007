import React, { useEffect, useMemo } from 'react'
import { useGetRadioHosts } from '@api/queries/radio/hosts'
import Loader from '@components/loader/Loader'
import { telegramService } from '@services/telegram/telegramService'
import { _t } from '@locales/index'
import RadioHost from '@containers/radio/components/RadioHost'
import { TypeBaseRadioHost, TypeRadioUserType } from '@customTypes/radio'
import { RadioUserTypeEnum } from '@enums/radio'

interface Props {
  radioId: string
  userHostIds: string[]
  selectedHostIds: string[]
  selectedCategoryIds: number[]
  onSelectHost: (hostId: string) => void
  onUnselectHost: (hostId: string) => void
  onClickNext: () => void
  onClickBack?: () => void
  showBackButton?: boolean
  userType: TypeRadioUserType
}

const RadioHosts: React.FC<Props> = ({
  radioId,
  userHostIds,
  selectedHostIds,
  selectedCategoryIds,
  onSelectHost,
  onUnselectHost,
  onClickNext,
  onClickBack = () => {},
  showBackButton = false,
  userType,
}) => {
  const { data: recommendedHostsResponse, isLoading: recommendedHostsLoading } =
    useGetRadioHosts(
      {
        radioId,
        includeUsersWithCategories: selectedCategoryIds,
        visibleToConsumers: userType === RadioUserTypeEnum.Listener,
      },
      {
        enabled: Boolean(radioId && selectedCategoryIds.length > 0),
      },
    )

  const { data: otherHostsResponse, isLoading: otherHostsLoading } =
    useGetRadioHosts(
      {
        radioId,
        excludeUsersWithCategories: selectedCategoryIds,
        visibleToConsumers: userType === RadioUserTypeEnum.Listener,
      },
      {
        enabled: Boolean(radioId),
      },
    )

  useEffect(() => {
    telegramService.mainButton.show().onClick(onClickNext)
    return () => {
      telegramService.mainButton.hide().offClick(onClickNext)
    }
  }, [onClickNext])

  useEffect(() => {
    if (showBackButton) {
      telegramService.backButton.show().onClick(onClickBack)
    } else {
      telegramService.backButton.hide().offClick(onClickBack)
    }
    return () => {
      telegramService.backButton.hide().offClick(onClickBack)
    }
  }, [onClickBack, showBackButton])

  useEffect(() => {
    if (selectedHostIds && selectedHostIds.length > 0) {
      telegramService.setMainButtonText(_t('done'))
    } else {
      telegramService.setMainButtonText(_t('skip'))
    }
  }, [selectedHostIds])

  const userHosts = useMemo(() => {
    const combinedHosts = [
      ...(recommendedHostsResponse?.radioHosts ?? []),
      ...(otherHostsResponse?.radioHosts ?? []),
    ]
    return combinedHosts.filter(host => userHostIds.includes(host.id))
  }, [recommendedHostsResponse, otherHostsResponse, userHostIds])

  const nonSelectedRecommendedHosts = useMemo(() => {
    return (
      recommendedHostsResponse?.radioHosts.filter(
        host => !userHostIds.includes(host.id),
      ) || []
    )
  }, [recommendedHostsResponse, userHostIds])

  const nonSelectedOtherHosts = useMemo(() => {
    return (
      otherHostsResponse?.radioHosts.filter(
        host => !userHostIds.includes(host.id),
      ) || []
    )
  }, [otherHostsResponse, userHostIds])

  const renderHosts = useMemo(
    () => (hosts: TypeBaseRadioHost[]) =>
      (
        <div className='flex flex-col gap-2'>
          {hosts.map(host => (
            <RadioHost
              key={host.id}
              host={host}
              isSelected={selectedHostIds.includes(host.id)}
              onSelect={onSelectHost}
              onUnselect={onUnselectHost}
              selectedCategoryIds={selectedCategoryIds}
            />
          ))}
        </div>
      ),
    [selectedHostIds, onSelectHost, onUnselectHost, selectedCategoryIds],
  )

  const renderSectionWithDivider = (
    hosts: TypeBaseRadioHost[],
    showDivider: boolean,
    title?: string,
  ) => {
    if (hosts.length === 0) {
      return null
    }
    return (
      <>
        {title && <p className='text-base font-bold'>{title}</p>}
        {renderHosts(hosts)}
        {showDivider && <div className='my-4 w-full h-px bg-text-color'></div>}
      </>
    )
  }

  const showRecommendedDivider =
    userHosts.length > 0 && nonSelectedRecommendedHosts.length > 0
  const showOtherDivider =
    (userHosts.length > 0 || nonSelectedRecommendedHosts.length > 0) &&
    nonSelectedOtherHosts.length > 0

  console.log('nonSelectedRecommendedHosts', nonSelectedRecommendedHosts)

  return (
    <div className='flex flex-col items-center gap-3'>
      <h1 className='h4 text-center'>{_t('chooseRadioHostsTitle')}</h1>
      <div className='flex flex-col w-full gap-3'>
        {renderSectionWithDivider(
          userHosts,
          showRecommendedDivider,
          _t('userHostsTitle'),
        )}
        {renderSectionWithDivider(
          nonSelectedRecommendedHosts,
          showOtherDivider,
          _t('recommendedHostsTitle'),
        )}
        {renderSectionWithDivider(nonSelectedOtherHosts, false)}
      </div>
      <Loader
        visible={recommendedHostsLoading || otherHostsLoading}
        fullWidth
      />
    </div>
  )
}

export default RadioHosts
