export enum RadioCategoryContentTypeEnum {
  Dynamic = 'Dynamic',
  Static = 'Static',
}

export enum RadioUserTypeEnum {
  Host = 'host',
  Owner = 'owner',
  Listener = 'listener',
}

export enum RadioCategoryRepostOption {
  TwiceADay = 'Twice_A_Day',
  OnceADay = 'Once_A_Day',
  OnceIn3Days = 'Once_A_3_Days',
  OnceAWeek = 'Once_A_Week',
  OnceAMonth = 'Once_A_Month',
}

export enum RadioHostRoleEnum {
  Owner = 'Owner',
  Admin = 'Admin',
  Host = 'Host',
  Partner = 'Partner',
}

export enum ChooseRadioDataScreenEnum {
  ChooseCategories,
  ChooseHosts,
}

export enum ManageCategoriesScreenEnum {
  Categories,
  UpdateCategory,
  ViewPosts,
}

export enum ManageHostsScreenEnum {
  Hosts,
  UpdateHost,
  ViewPosts,
}

export enum RadioCategoryFormEnum {
  Name = 'name',
  ContentType = 'contentType',
  SystemName = 'sysName',
}

export enum RadioHostFormEnum {
  Name = 'name',
  Bio = 'bio',
  Role = 'role',
  EnglishName = 'englishName',
  WelcomeMessage = 'welcomeMessage',
  ProfileImage = 'profileImage',
  PreviewImage = 'previewImage',
  MostRelevantCategoryIds = 'mostRelevantCategoryIds',
  AdditionalCategoryIds = 'additionalCategoryIds',
  IsVisibleToConsumers = 'isVisibleToConsumers',
}

export enum RadioHostSearchEnum {
  Name = 'name',
}

export enum EditHostCategoryEnum {
  MostRelevant = 'Most Relevant',
  Additional = 'Additional',
}
