import React, { useEffect, useMemo } from 'react'
import { useGetRadioCategories } from '@api/queries/radio/categories'
import Loader from '@components/loader/Loader'
import { TypeOptions } from '@customTypes/common'
import { createTypeOptions } from '@common/index'
import Chip from '@components/chip/Chip'
import { telegramService } from '@services/telegram/telegramService'
import { _t } from '@locales/index'

interface Props {
  radioId: string
  selectedCategoryIds: number[]
  onSelectCategory: (categoryId: number) => void
  onUnselectCategory: (categoryId: number) => void
  onClickNext: () => void
  minCategoriesLimit?: number
  maxCategoriesLimit?: number
  showBackButton?: boolean
  onClickBack?: () => void
}

const RadioCategories: React.FC<Props> = ({
  radioId,
  selectedCategoryIds,
  onSelectCategory,
  onUnselectCategory,
  onClickNext = () => {},
  minCategoriesLimit = 0,
  maxCategoriesLimit = Infinity,
  showBackButton = false,
  onClickBack = () => {},
}) => {
  const { data: radioCategoriesResponse, isLoading } = useGetRadioCategories(
    radioId,
    {
      enabled: Boolean(radioId),
    },
  )

  const categoryOptions = useMemo<TypeOptions<number> | null>(() => {
    if (!radioCategoriesResponse) {
      return null
    }
    return createTypeOptions(
      radioCategoriesResponse.radioCategories,
      'name',
      'id',
    )
  }, [radioCategoriesResponse])

  useEffect(() => {
    telegramService.mainButton.show().onClick(onClickNext)
    return () => {
      telegramService.mainButton.offClick(onClickNext)
    }
  }, [onClickNext])

  useEffect(() => {
    if (showBackButton) {
      telegramService.backButton.show().onClick(onClickBack)
      return () => {
        telegramService.backButton.hide().offClick(onClickBack)
      }
    }
  }, [onClickBack, showBackButton])

  useEffect(() => {
    const isAnythingSelected =
      selectedCategoryIds && selectedCategoryIds.length > 0
    const isSelectionLimit = minCategoriesLimit > 0
    const isMinLimitSatisfied =
      selectedCategoryIds && selectedCategoryIds.length >= minCategoriesLimit
    const isMaxLimitExceeded =
      maxCategoriesLimit !== undefined
        ? selectedCategoryIds.length > maxCategoriesLimit
        : false

    let mainButtonText
    if (isSelectionLimit && !isMinLimitSatisfied) {
      mainButtonText = _t('continueMinLength', { length: minCategoriesLimit })
    } else if (isMaxLimitExceeded) {
      mainButtonText = _t('continueMaxLength', { length: maxCategoriesLimit })
    } else {
      mainButtonText = isAnythingSelected ? _t('continue') : _t('skip')
    }
    telegramService.setMainButtonText(mainButtonText)

    if ((isSelectionLimit && !isMinLimitSatisfied) || isMaxLimitExceeded) {
      telegramService.disableMainButton()
    } else {
      telegramService.enableMainButton()
    }
  }, [minCategoriesLimit, maxCategoriesLimit, selectedCategoryIds])

  return (
    <div className='flex flex-col items-center gap-3'>
      <h1 className='h4 text-center'>{_t('chooseRadioCategoriesTitle')}</h1>
      <div className='flex flex-wrap gap-2 items-center'>
        {categoryOptions &&
          categoryOptions.map(categoryOption => {
            const isSelected = selectedCategoryIds.some(
              selectedCategoryId => selectedCategoryId === categoryOption.value,
            )

            return (
              <Chip<number>
                key={categoryOption.value}
                data={categoryOption}
                isSelected={isSelected}
                onSelect={onSelectCategory}
                onUnselect={onUnselectCategory}
              />
            )
          })}
      </div>
      <Loader visible={isLoading} fullWidth />
    </div>
  )
}

export default RadioCategories
