export enum MediaTypeEnum {
  ProfileMedia = 'PM',
  CommunityMedia = 'CO',
}

export enum MediaFileTypeEnum {
  Audio = 'AU',
  Image = 'IM',
  Video = 'VI',
  Document = 'DO',
}

export enum ProfileMediaTypeEnum {
  Gallery = 'GA',
  Profile = 'PR',
  Cover = 'CO',
}

export enum CommunityMediaTypeEnum {
  Gallery = 'GA',
  Community = 'CR',
  Cover = 'CO',
  WelcomeVideo = 'WV',
}
