import { array, bool, number, object, string } from 'yup'
import {
  RadioCategoryContentTypeEnum,
  RadioCategoryFormEnum,
  RadioHostFormEnum,
  RadioHostRoleEnum,
} from '@enums/radio'
import { _t } from '@locales/index'

export const radioCategoryValidationSchema = object().shape({
  [RadioCategoryFormEnum.Name]: string()
    .min(
      2,
      _t('minLengthValidation', {
        fieldName: _t('updateCategoryNameLabel'),
        length: 2,
      }),
    )
    .max(
      255,
      _t('maxLengthValidation', {
        fieldName: _t('updateCategoryNameLabel'),
        length: 255,
      }),
    )
    .required(
      _t('requiredValidation', {
        fieldName: _t('updateCategoryNameLabel'),
      }),
    ),
  [RadioCategoryFormEnum.SystemName]: string()
    .min(
      2,
      _t('minLengthValidation', {
        fieldName: _t('updateCategorySystemNameLabel'),
        length: 2,
      }),
    )
    .max(
      255,
      _t('maxLengthValidation', {
        fieldName: _t('updateCategorySystemNameLabel'),
        length: 255,
      }),
    ),
  [RadioCategoryFormEnum.ContentType]: string()
    .oneOf(
      [
        RadioCategoryContentTypeEnum.Dynamic,
        RadioCategoryContentTypeEnum.Static,
      ],
      _t('valueValidation', {
        fieldName: _t('updateCategoryContentTypeLabel'),
      }),
    )
    .required(
      _t('requiredValidation', {
        fieldName: _t('updateCategoryContentTypeLabel'),
      }),
    ),
})

export const radioHostValidationSchema = object().shape({
  [RadioHostFormEnum.Name]: string()
    .min(
      2,
      _t('minLengthValidation', {
        fieldName: _t('updateHostNameLabel'),
        length: 2,
      }),
    )
    .max(
      255,
      _t('maxLengthValidation', {
        fieldName: _t('updateHostNameLabel'),
        length: 255,
      }),
    )
    .required(
      _t('requiredValidation', {
        fieldName: _t('updateHostNameLabel'),
      }),
    ),
  [RadioHostFormEnum.EnglishName]: string()
    .min(
      2,
      _t('minLengthValidation', {
        fieldName: _t('updateHostEnglishNameLabel'),
        length: 2,
      }),
    )
    .max(
      255,
      _t('maxLengthValidation', {
        fieldName: _t('updateHostEnglishNameLabel'),
        length: 255,
      }),
    ),
  [RadioHostFormEnum.Bio]: string()
    .min(
      2,
      _t('minLengthValidation', {
        fieldName: _t('updateHostBioLabel'),
        length: 2,
      }),
    )
    .max(
      250,
      _t('maxLengthValidation', {
        fieldName: _t('updateHostBioLabel'),
        length: 250,
      }),
    )
    .required(
      _t('requiredValidation', {
        fieldName: _t('updateHostBioLabel'),
      }),
    ),
  [RadioHostFormEnum.WelcomeMessage]: string()
    .min(
      2,
      _t('minLengthValidation', {
        fieldName: _t('updateHostBioLabel'),
        length: 2,
      }),
    )
    .max(
      512,
      _t('maxLengthValidation', {
        fieldName: _t('updateHostBioLabel'),
        length: 250,
      }),
    ),
  [RadioHostFormEnum.Role]: string()
    .oneOf(
      [
        RadioHostRoleEnum.Owner,
        RadioHostRoleEnum.Admin,
        RadioHostRoleEnum.Host,
        RadioHostRoleEnum.Partner,
      ],
      _t('valueValidation', {
        fieldName: _t('updateHostProfileImageLabel'),
      }),
    )
    .required(
      _t('requiredValidation', {
        fieldName: _t('updateHostRoleLabel'),
      }),
    ),
  [RadioHostFormEnum.MostRelevantCategoryIds]: array()
    .of(number().integer())
    .min(
      1,
      _t('minLengthValidation', {
        fieldName: _t('updateHostMostRelevantCategoriesLabel'),
        length: 1,
      }),
    )
    .max(
      5,
      _t('maxLengthValidation', {
        fieldName: _t('updateHostMostRelevantCategoriesLabel'),
        length: 5,
      }),
    )
    .required(
      _t('requiredValidation', {
        fieldName: _t('updateHostMostRelevantCategoriesLabel'),
      }),
    ),
  [RadioHostFormEnum.AdditionalCategoryIds]: array()
    .of(number().integer())
    .min(
      0,
      _t('minLengthValidation', {
        fieldName: _t('updateHostAdditionalCategoriesLabel'),
        length: 0,
      }),
    )
    .max(
      5,
      _t('maxLengthValidation', {
        fieldName: _t('updateHostAdditionalCategoriesLabel'),
        length: 5,
      }),
    )
    .notRequired(),
  [RadioHostFormEnum.IsVisibleToConsumers]: bool().required(),
})
