import React, { useMemo } from 'react'
import Lottie from 'lottie-react'
import LoaderAnimation from '@assets/lottie-animations/loader.json'

interface Props {
  visible: boolean
  size?: number
  fullWidth?: boolean
}

const Loader: React.FC<Props> = ({ visible, fullWidth = true, size }) => {
  const loaderAnimation = useMemo(
    () => (
      <Lottie
        animationData={LoaderAnimation}
        loop
        style={{
          width: size,
          height: size,
        }}
      />
    ),
    [size],
  )

  if (!visible) {
    return null
  }

  if (fullWidth) {
    return (
      <div className='absolute inset-0 flex justify-center items-center bg-white/80 z-50 min-h-screen min-w-screen'>
        <div className='w-[150px] h-[150px]'>{loaderAnimation}</div>
      </div>
    )
  }

  return <div className='w-[150px] h-[150px]'>{loaderAnimation}</div>
}

export default Loader
