import React, { useCallback } from 'react'
import classNames from 'classnames'

import { TypeOption } from '@customTypes/common'
import { ReactComponent as Cross } from '@assets/images/common/cross_1.svg'

interface Props<T> {
  data: TypeOption<T>
  isSelected: boolean
  onSelect?: (value: T) => void
  onUnselect?: (value: T) => void
  selectable?: boolean
  disabled?: boolean
}

const Chip = <T,>({
  data,
  isSelected,
  onSelect = () => {},
  onUnselect = () => {},
  selectable = true,
  disabled = false,
}: Props<T>) => {
  const handleUnselect = useCallback(
    (e: React.MouseEvent, id: T) => {
      e.stopPropagation()
      onUnselect(id)
    },
    [onUnselect],
  )

  const handleSelect = useCallback(() => {
    if (!disabled) {
      onSelect(data.value)
    }
  }, [data.value, disabled, onSelect])

  return (
    <div
      className={classNames(
        'flex gap-1.5 items-center border rounded-full py-1 transition',
        disabled
          ? 'border-custom-gray-3'
          : isSelected
          ? 'border-custom-pink custom-shadow-2'
          : 'cursor-pointer',
        isSelected && selectable && !disabled ? 'pl-6 pr-[18px]' : 'px-6',
      )}
      onClick={handleSelect}
    >
      <p
        className={classNames(
          'body3 transition-colors',
          isSelected && 'text-custom-pink',
          disabled && 'text-custom-gray-2',
        )}
      >
        {data.label}
      </p>
      {(isSelected && selectable) || disabled ? (
        <Cross
          className={classNames(
            'w-5 h-5',
            disabled ? 'fill-custom-gray-2' : 'fill-custom-pink cursor-pointer',
          )}
          onClick={e => handleUnselect(e, data.value)}
        />
      ) : null}
    </div>
  )
}

export default Chip
