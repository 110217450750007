export const VIDEO_SUFFIX_TYPES = ['mov', 'mp4', 'm4a', 'm4v', 'webm']
export const IMAGE_SUFFIX_TYPES = ['jpeg', 'png', 'jpg']
export const AUDIO_SUFFIX_TYPES = ['aac', 'mp3', 'm4a', 'wav']
export const DOCUMENT_SUFFIX_TYPES = ['pdf']
export const ALL_SUFFIX_TYPES = [
  ...VIDEO_SUFFIX_TYPES,
  ...IMAGE_SUFFIX_TYPES,
  ...AUDIO_SUFFIX_TYPES,
  ...DOCUMENT_SUFFIX_TYPES,
]

export const ACCEPTED_IMAGE_FILE_FORMATS = [
  'image/jpeg',
  'image/png',
  'image/jpg',
]

export const ACCEPTED_AUDIO_FILE_FORMATS = [
  'audio/x-m4a',
  'audio/mp3',
  'audio/mpeg',
  'audio/x-wav',
  'audio/wav',
  'audio/aac',
  'audio/m4a',
  'audio/aac',
]

export const ACCEPTED_VIDEO_FILE_FORMATS = [
  'video/mp4',
  'video/x-flv',
  'video/MP2T',
  'video/x-ms-wmv',
  'video/quicktime',
  'video/3gpp',
  'video/mpeg',
]

export const ACCEPTED_DOCUMENT_FILE_FORMATS = ['application/pdf']

export const ACCEPTED_FILE_FORMATS = [
  ...ACCEPTED_IMAGE_FILE_FORMATS,
  ...ACCEPTED_AUDIO_FILE_FORMATS,
  ...ACCEPTED_VIDEO_FILE_FORMATS,
  ...ACCEPTED_DOCUMENT_FILE_FORMATS,
]
