import { useMutation, UseMutationOptions } from 'react-query'
import { ApiKeyNameEnum } from '@enums/api'
import { botatoApiService } from '@services/api/apiService'
import { AxiosError } from 'axios'
import {
  TypePreUploadMediaPayload,
  TypePreUploadMediaResponse,
} from '@customTypes/media'

/*****************************************
 PreUpload
 ****************************************/
type TypePreUploadOptions = UseMutationOptions<
  TypePreUploadMediaResponse,
  AxiosError,
  TypePreUploadMediaPayload
>

const preUploadMedia = async (
  values: TypePreUploadMediaPayload,
): Promise<TypePreUploadMediaResponse> => {
  try {
    const response = await botatoApiService.post(
      ApiKeyNameEnum.pre_upload_media,
      values,
    )
    return response.data
  } catch (err) {
    throw err
  }
}

export const usePreUploadMedia = (options?: TypePreUploadOptions) => {
  return useMutation(preUploadMedia, {
    ...options,
  })
}

/*****************************************
 setMediaPublic
 ****************************************/

type TypeSetPublicOptions = UseMutationOptions<
  null,
  AxiosError,
  TypePreUploadMediaResponse
>

const setMediaPublic = async (
  values: TypePreUploadMediaResponse,
): Promise<null> => {
  try {
    await botatoApiService.post(ApiKeyNameEnum.set_media_public, values)
    return null
  } catch (err) {
    throw err
  }
}

export const useSetMediaPublic = (options?: TypeSetPublicOptions) => {
  return useMutation(setMediaPublic, {
    ...options,
  })
}
