import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import RadioCategories from '@containers/radio/pages/RadioCategories'
import {
  TypeChooseCategoriesScriptParams,
  TypeRadioCategory,
} from '@customTypes/radio'
import { useStartBotScript } from '@api/queries/botScript'
import { TypeStartBotScriptPayload } from '@customTypes/botScript'
import { telegramService } from '@services/telegram/telegramService'
import {
  useGetBotUserRadioCategories,
  useGetRadioCategories,
} from '@api/queries/radio/categories'
import { _t } from '@locales/index'

const ChooseCategories: React.FC = () => {
  const [searchParams] = useSearchParams()
  const radioId = searchParams.get('radio_id') || ''
  const botUserId = searchParams.get('bot_user_id') || ''
  const minCategories = searchParams.get('min_categories')
  const maxCategories = searchParams.get('max_categories')

  const { mutate: startBotScript } =
    useStartBotScript<TypeChooseCategoriesScriptParams>()

  const [selectedCategoryIds, setSelectedCategoryIds] = useState<number[]>([])

  const { data: radioCategoriesResponse } = useGetRadioCategories(radioId, {
    enabled: Boolean(radioId),
  })
  const { data: botUserRadioCategoriesResponse } = useGetBotUserRadioCategories(
    botUserId,
    {
      enabled: Boolean(botUserId),
    },
  )

  const selectedCategories = useMemo<TypeRadioCategory[] | null>(() => {
    if (
      !radioCategoriesResponse ||
      !selectedCategoryIds ||
      selectedCategoryIds.length === 0
    ) {
      return null
    }

    return radioCategoriesResponse.radioCategories.filter(category =>
      selectedCategoryIds.includes(category.id),
    )
  }, [radioCategoriesResponse, selectedCategoryIds])

  const handleSelectCategory = useCallback((categoryId: number) => {
    setSelectedCategoryIds(prevCategoryIds => [...prevCategoryIds, categoryId])
  }, [])

  const handleUnselectCategory = useCallback((categoryId: number) => {
    setSelectedCategoryIds(prevCategoryIds =>
      prevCategoryIds.filter(id => id !== categoryId),
    )
  }, [])

  const handleSubmit = useCallback(() => {
    const radioSelectedCategoryNames =
      selectedCategories?.map(category => category.name).join(',') ||
      _t('noCategories')

    const payload: TypeStartBotScriptPayload<TypeChooseCategoriesScriptParams> =
      {
        botId: searchParams.get('bot_id') || '',
        userId: searchParams.get('user_id') || '',
        scriptId: searchParams.get('next_script_id') || '',
        step: parseInt(searchParams.get('next_script_step') || '1'),
        params: {
          radio_selected_category_ids: selectedCategoryIds,
          radio_selected_category_names: radioSelectedCategoryNames,
        },
      }

    startBotScript(payload, {
      onSuccess: async () => {
        telegramService.close(true)
      },
    })
  }, [searchParams, selectedCategories, selectedCategoryIds, startBotScript])

  useEffect(() => {
    if (botUserRadioCategoriesResponse) {
      const userCategoryIds =
        botUserRadioCategoriesResponse.radioCategories.map(
          category => category.id,
        )
      setSelectedCategoryIds(userCategoryIds)
    }
  }, [botUserRadioCategoriesResponse])

  const minCategoriesLimit = useMemo<number>(() => {
    if (!minCategories) {
      return 0
    }

    const minCategoriesNumber = parseInt(minCategories, 10)

    if (isNaN(minCategoriesNumber)) {
      return 0
    }

    return minCategoriesNumber
  }, [minCategories])

  const maxCategoriesLimit = useMemo<number>(() => {
    if (!maxCategories) {
      return Infinity
    }

    const maxCategoriesNumber = parseInt(maxCategories, 10)

    if (isNaN(maxCategoriesNumber)) {
      return Infinity
    }

    return maxCategoriesNumber
  }, [maxCategories])

  return (
    <div className='flex flex-col p-5'>
      <RadioCategories
        radioId={radioId}
        selectedCategoryIds={selectedCategoryIds}
        onSelectCategory={handleSelectCategory}
        onUnselectCategory={handleUnselectCategory}
        minCategoriesLimit={minCategoriesLimit}
        maxCategoriesLimit={maxCategoriesLimit}
        onClickNext={handleSubmit}
      />
    </div>
  )
}

export default ChooseCategories
