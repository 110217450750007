import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import RadioCategories from '@containers/radio/pages/RadioCategories'
import {
  TypeBaseRadioHost,
  TypeChooseRadioDataScreen,
  TypeChooseRadioDataScriptParams,
  TypeRadioCategory,
  TypeRadioUserType,
} from '@customTypes/radio'
import { ChooseRadioDataScreenEnum, RadioUserTypeEnum } from '@enums/radio'
import RadioHosts from '@containers/radio/pages/RadioHosts'
import { useStartBotScript } from '@api/queries/botScript'
import { TypeStartBotScriptPayload } from '@customTypes/botScript'
import { telegramService } from '@services/telegram/telegramService'
import {
  useGetBotUserRadioCategories,
  useGetRadioCategories,
} from '@api/queries/radio/categories'
import {
  useGetBotUserRadioHosts,
  useGetRadioHosts,
} from '@api/queries/radio/hosts'
import { _t } from '@locales/index'
import {
  useGetRadioMessageRelatedData,
  useUpdateRadioMessageRelatedData,
} from '@api/queries/radio/messages'
import Loader from '@components/loader/Loader'

interface Props {
  botSchedulerId?: number
  onClickBack?: () => void
}

const ChooseRadioData: React.FC<Props> = ({
  botSchedulerId,
  onClickBack = () => {},
}) => {
  const [searchParams] = useSearchParams()
  const radioId = searchParams.get('radio_id') || ''
  const botUserId = searchParams.get('bot_user_id') || ''
  const minCategories = searchParams.get('min_categories')
  const maxCategories = searchParams.get('max_categories')
  const userType = searchParams.get('user_type') as TypeRadioUserType

  const { mutate: startBotScript } =
    useStartBotScript<TypeChooseRadioDataScriptParams>()

  const [currentScreen, setCurrentScreen] = useState<TypeChooseRadioDataScreen>(
    ChooseRadioDataScreenEnum.ChooseCategories,
  )
  const [selectedCategoryIds, setSelectedCategoryIds] = useState<number[]>([])
  const [selectedHostIds, setSelectedHostIds] = useState<string[]>([])
  const [userHostIds, setUserHostIds] = useState<string[]>([])

  const { data: radioCategoriesResponse } = useGetRadioCategories(radioId, {
    enabled: Boolean(radioId),
  })
  const { data: botUserRadioCategoriesResponse } = useGetBotUserRadioCategories(
    botUserId,
    {
      enabled: Boolean(botUserId && userType === RadioUserTypeEnum.Listener),
    },
  )

  const { data: botUserRadioHostsResponse } = useGetBotUserRadioHosts(
    botUserId,
    {
      enabled: Boolean(botUserId && userType === RadioUserTypeEnum.Listener),
    },
  )

  const { data: radioMessageRelatedData } = useGetRadioMessageRelatedData(
    botSchedulerId?.toString() || '',
    {
      enabled: Boolean(botSchedulerId),
    },
  )

  const { data: radioHostsResponse } = useGetRadioHosts(
    { radioId },
    {
      enabled: Boolean(radioId),
    },
  )

  const {
    mutate: updateMessageRelatedData,
    isLoading: isUpdatingMessageRelatedData,
  } = useUpdateRadioMessageRelatedData()

  const selectedCategories = useMemo<TypeRadioCategory[] | null>(() => {
    if (
      !radioCategoriesResponse ||
      !selectedCategoryIds ||
      selectedCategoryIds.length === 0
    ) {
      return null
    }

    return radioCategoriesResponse.radioCategories.filter(category =>
      selectedCategoryIds.includes(category.id),
    )
  }, [radioCategoriesResponse, selectedCategoryIds])

  const selectedRadioHosts = useMemo<TypeBaseRadioHost[] | null>(() => {
    if (
      !radioHostsResponse ||
      !selectedHostIds ||
      selectedHostIds.length === 0
    ) {
      return null
    }

    return radioHostsResponse.radioHosts.filter(host =>
      selectedHostIds.includes(host.id),
    )
  }, [radioHostsResponse, selectedHostIds])

  const handleSelectCategory = useCallback((categoryId: number) => {
    setSelectedCategoryIds(prevCategoryIds => [...prevCategoryIds, categoryId])
  }, [])

  const handleUnselectCategory = useCallback((categoryId: number) => {
    setSelectedCategoryIds(prevCategoryIds =>
      prevCategoryIds.filter(id => id !== categoryId),
    )
  }, [])

  const handleSelectHost = useCallback((hostId: string) => {
    setSelectedHostIds(prevHostIds => [...prevHostIds, hostId])
  }, [])

  const handleUnselectHost = useCallback((hostId: string) => {
    setSelectedHostIds(prevHostIds => prevHostIds.filter(id => id !== hostId))
  }, [])

  const handleSubmit = useCallback(() => {
    if (botSchedulerId) {
      updateMessageRelatedData(
        {
          botSchedulerId: botSchedulerId.toString(),
          radioCategoryIds: selectedCategoryIds,
          radioHostIds: selectedHostIds,
        },
        {
          onSuccess: async () => {
            onClickBack()
          },
        },
      )
    } else {
      const radioSelectedHostNames =
        selectedRadioHosts?.map(host => host.name).join(',') || _t('noHosts')
      const radioSelectedCategoryNames =
        selectedCategories?.map(category => category.name).join(',') ||
        _t('noCategories')

      const payload: TypeStartBotScriptPayload<TypeChooseRadioDataScriptParams> =
        {
          botId: searchParams.get('bot_id') || '',
          userId: searchParams.get('user_id') || '',
          scriptId: searchParams.get('next_script_id') || '',
          step: parseInt(searchParams.get('next_script_step') || '1'),
          params: {
            radio_selected_host_ids: selectedHostIds,
            radio_selected_host_names: radioSelectedHostNames,
            radio_selected_category_ids: selectedCategoryIds,
            radio_selected_category_names: radioSelectedCategoryNames,
          },
        }

      startBotScript(payload, {
        onSuccess: async () => {
          telegramService.close(true)
        },
      })
    }
  }, [
    botSchedulerId,
    onClickBack,
    searchParams,
    selectedCategories,
    selectedCategoryIds,
    selectedHostIds,
    selectedRadioHosts,
    startBotScript,
    updateMessageRelatedData,
  ])

  const handleSubmitRadioCategories = useCallback(() => {
    if (userType === RadioUserTypeEnum.Host) {
      handleSubmit()
    } else {
      setCurrentScreen(ChooseRadioDataScreenEnum.ChooseHosts)
    }
  }, [handleSubmit, userType])

  const handleClickCategoryBack = useCallback(() => {
    if (botSchedulerId) {
      onClickBack()
    }
  }, [botSchedulerId, onClickBack])

  useEffect(() => {
    if (botUserRadioCategoriesResponse && !radioMessageRelatedData) {
      const userCategoryIds =
        botUserRadioCategoriesResponse.radioCategories.map(
          category => category.id,
        )
      setSelectedCategoryIds(userCategoryIds)
    }
  }, [botUserRadioCategoriesResponse, radioMessageRelatedData])

  useEffect(() => {
    if (botUserRadioHostsResponse && !radioMessageRelatedData) {
      const botUserHostIds = botUserRadioHostsResponse.radioHosts.map(
        host => host.id,
      )
      setUserHostIds(botUserHostIds)
      setSelectedHostIds(botUserHostIds)
    }
  }, [botUserRadioHostsResponse, radioMessageRelatedData])

  useEffect(() => {
    if (radioMessageRelatedData) {
      setSelectedHostIds(radioMessageRelatedData.radioHostIds)
      setSelectedCategoryIds(radioMessageRelatedData.radioCategoryIds)
    }
  }, [radioMessageRelatedData])

  const minCategoriesLimit = useMemo<number>(() => {
    if (!minCategories) {
      return 0
    }

    const minCategoriesNumber = parseInt(minCategories, 10)

    if (isNaN(minCategoriesNumber)) {
      return 0
    }

    return minCategoriesNumber
  }, [minCategories])

  const maxCategoriesLimit = useMemo<number>(() => {
    if (!maxCategories) {
      return Infinity
    }

    const maxCategoriesNumber = parseInt(maxCategories, 10)

    if (isNaN(maxCategoriesNumber)) {
      return Infinity
    }

    return maxCategoriesNumber
  }, [maxCategories])

  const renderScreen = useMemo(() => {
    switch (currentScreen) {
      case ChooseRadioDataScreenEnum.ChooseCategories:
        return (
          <RadioCategories
            radioId={radioId}
            selectedCategoryIds={selectedCategoryIds}
            onSelectCategory={handleSelectCategory}
            onUnselectCategory={handleUnselectCategory}
            minCategoriesLimit={minCategoriesLimit}
            maxCategoriesLimit={maxCategoriesLimit}
            onClickNext={handleSubmitRadioCategories}
            showBackButton={Boolean(botSchedulerId)}
            onClickBack={handleClickCategoryBack}
          />
        )
      case ChooseRadioDataScreenEnum.ChooseHosts:
        return (
          <RadioHosts
            radioId={radioId}
            userHostIds={userHostIds}
            selectedHostIds={selectedHostIds}
            onSelectHost={handleSelectHost}
            onUnselectHost={handleUnselectHost}
            onClickNext={handleSubmit}
            onClickBack={() =>
              setCurrentScreen(ChooseRadioDataScreenEnum.ChooseCategories)
            }
            showBackButton={true}
            selectedCategoryIds={selectedCategoryIds}
            userType={userType}
          />
        )
      default:
        return null
    }
  }, [
    botSchedulerId,
    currentScreen,
    handleClickCategoryBack,
    handleSelectCategory,
    handleSelectHost,
    handleSubmit,
    handleSubmitRadioCategories,
    handleUnselectCategory,
    handleUnselectHost,
    maxCategoriesLimit,
    minCategoriesLimit,
    radioId,
    selectedCategoryIds,
    selectedHostIds,
    userHostIds,
    userType,
  ])

  return (
    <div className='flex flex-col p-5'>
      {renderScreen}
      <Loader visible={isUpdatingMessageRelatedData} fullWidth />
    </div>
  )
}

export default ChooseRadioData
