import React, { useMemo } from 'react'
import { TypeRadioMessage } from '@customTypes/radio'
import { _t } from '@locales/index'
import { useGetScheduledRadioMessagesByHost } from '@api/queries/radio/messages'
import moment from 'moment'
import Post from '@containers/radio/components/Post'
import { useSearchParams } from 'react-router-dom'
import { useGetRadioHostByBotUser } from '@api/queries/radio/hosts'

const HostScheduledPosts: React.FC = () => {
  const [searchParams] = useSearchParams()
  const radioId = searchParams.get('radio_id') || ''
  const botUserId = searchParams.get('bot_user_id') || ''

  const { data: radioHost } = useGetRadioHostByBotUser(
    {
      radioId: radioId,
      botUserId: botUserId,
    },
    {
      enabled: Boolean(radioId && botUserId),
    },
  )

  const { data: radioMessagesResponse } = useGetScheduledRadioMessagesByHost(
    radioHost?.id || '',
    {
      enabled: Boolean(radioHost?.id),
    },
  )

  const sortedRadioMessages = useMemo<TypeRadioMessage[] | null>(() => {
    if (
      !radioMessagesResponse?.radioMessages ||
      radioMessagesResponse.radioMessages.length === 0
    ) {
      return null
    }
    return radioMessagesResponse.radioMessages.sort((a, b) => {
      const momentA = moment(a.botScheduler.nextPostDate)
      const momentB = moment(b.botScheduler.nextPostDate)
      return momentB.diff(momentA)
    })
  }, [radioMessagesResponse?.radioMessages])

  if (!sortedRadioMessages) {
    return <p className='text-center'>{_t('noScheduledPosts')}</p>
  }

  return (
    <div className='flex flex-col gap-5 items-stretch secondary-bg p-5 h-full'>
      {sortedRadioMessages.map(radioMessage => (
        <Post
          key={radioMessage.id}
          radioMessage={radioMessage}
          showEdit={false}
        />
      ))}
    </div>
  )
}

export default HostScheduledPosts
