import React, { useEffect, useState } from 'react'
import { useGetRadioHosts } from '@api/queries/radio/hosts'
import Loader from '@components/loader/Loader'
import { telegramService } from '@services/telegram/telegramService'
import { _t } from '@locales/index'
import classNames from 'classnames'
import { ReactComponent as PlusIcon } from '@assets/images/common/plus.svg'
import RadioHost from '@containers/radio/components/RadioHost'

interface Props {
  radioId: string
  onSubmit: () => void
  onSelectHost: (hostId: string) => void
  onAddNew: () => void
}

const UpdateHosts: React.FC<Props> = ({
  radioId,
  onSubmit,
  onSelectHost,
  onAddNew,
}) => {
  const [searchName, setSearchName] = useState<string>('')
  const [debouncedSearchName, setDebouncedSearchName] = useState<string>('')
  const { data: radioHostsResponse, isLoading } = useGetRadioHosts(
    { radioId, searchName: debouncedSearchName },
    {
      enabled: Boolean(radioId),
    },
  )

  useEffect(() => {
    telegramService.mainButton.show().onClick(onSubmit)
    telegramService.setMainButtonText(_t('done'))
    return () => {
      telegramService.mainButton.offClick(onSubmit)
    }
  }, [onSubmit])

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedSearchName(searchName)
    }, 500)

    return () => {
      clearTimeout(timeout)
    }
  }, [searchName])

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchName(e.target.value)
  }

  return (
    <div className='flex flex-col items-center gap-3 w-full no-scrollbar p-5'>
      <div className='flex items-center w-full justify-between'>
        <h1 className='h4 text-center'>{_t('updateHostsTitle')}</h1>
        <div
          className='flex gap-1 items-center px-5 rounded-full transition border justify-center border-custom-teal bg-custom-sky bg-opacity-20 cursor-pointer h-8'
          onClick={onAddNew}
        >
          <p
            className={classNames(
              'text-[17px] transition-colors text-custom-teal',
            )}
          >
            {_t('addNew')}
          </p>
          <PlusIcon className='fill-custom-teal w-5' />
        </div>
      </div>
      {/* search input here */}
      <div className='flex flex-col w-full'>
        <input
          className='w-full border flex-1 placeholder:text-custom-gray-4 placeholder:no-underline focus:ring-0 outline-none rounded disabled:bg-custom-gray-5 disabled:border-none bg-theme px-4 py-2.5 body2 font-normal'
          placeholder={_t('radioHostSearchPlaceholder')}
          value={searchName}
          onChange={handleSearchChange}
        />
      </div>
      <div className='flex flex-col flex-wrap gap-[18px] items-center w-full'>
        {radioHostsResponse?.radioHosts.map(radioHost => {
          return (
            <RadioHost
              key={radioHost.id}
              host={radioHost}
              isSelected={false}
              onSelect={onSelectHost}
              selectable={false}
            />
          )
        })}
      </div>
      <Loader visible={isLoading} fullWidth />
    </div>
  )
}

export default UpdateHosts
