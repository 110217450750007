import React, { useMemo } from 'react'
import classNames from 'classnames'
import { ErrorMessage, useField } from 'formik'

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string
  id: string
  textClass?: string
  disabled?: boolean
  hideErrorMessage?: boolean
  fontWeightClass?: string
  labelClass?: string
  paddingClass?: string
  label?: string
}

const InputField: React.FC<Props> = ({
  className,
  textClass,
  hideErrorMessage,
  paddingClass,
  fontWeightClass,
  labelClass,
  label,
  ...props
}) => {
  const [field, meta] = useField(props)

  const error = useMemo(
    () => meta.touched && meta.error,
    [meta.error, meta.touched],
  )

  return (
    <div className='w-full flex flex-col gap-2'>
      {label && (
        <label
          htmlFor={props.id}
          className={classNames('body2 capitalize', labelClass)}
        >
          {label}
        </label>
      )}
      <input
        className={classNames(
          'w-full border flex-1 placeholder:text-custom-gray-4 placeholder:no-underline focus:ring-0 outline-none rounded disabled:bg-custom-gray-5 disabled:border-none bg-theme',
          error && 'border-custom-error-red bg-custom-red bg-opacity-10',
          className,
          textClass || 'body2',
          fontWeightClass || 'font-light',
          paddingClass || 'px-4 py-2.5',
        )}
        autoFocus={false}
        autoComplete='off'
        {...field}
        {...props}
      />
      {!hideErrorMessage && (
        <ErrorMessage
          name={props.name}
          component='p'
          className='text-custom-error-red caption mt-2'
        />
      )}
    </div>
  )
}

export default InputField
