import { useMutation, UseMutationOptions } from 'react-query'
import { ApiKeyNameEnum } from '@enums/api'
import { AxiosError } from 'axios'
import { botatoApiService } from '@services/api/apiService'
import { TypeStartBotScriptPayload } from '@customTypes/botScript'

/*****************************************
 Bot Scripts
 ****************************************/

type TypeStartBotScriptOptions<T = null> = UseMutationOptions<
  TypeStartBotScriptPayload<T>,
  AxiosError,
  TypeStartBotScriptPayload<T>
>

const startBotScript = async <T>(
  values: TypeStartBotScriptPayload<T>,
): Promise<TypeStartBotScriptPayload<T>> => {
  try {
    const response = await botatoApiService.post(
      ApiKeyNameEnum.start_bot_script,
      values,
    )
    return response.data
  } catch (err) {
    throw err
  }
}

export const useStartBotScript = <T>(
  options?: TypeStartBotScriptOptions<T>,
) => {
  return useMutation(startBotScript, {
    ...options,
  })
}
