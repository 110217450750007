import { useEffect } from 'react'
import moment from 'moment-timezone'
import { useUpdateBotUserTimezone } from '@api/queries/botUser'
import { useSearchParams } from 'react-router-dom'

const useSaveUserTimezone = () => {
  const [searchParams] = useSearchParams()
  const botUserId = searchParams.get('bot_user_id') || ''
  const { mutate: updateUserTimezone } = useUpdateBotUserTimezone()

  useEffect(() => {
    if (botUserId) {
      const userTimezone = moment.tz.guess()
      updateUserTimezone({
        botUserId,
        timezone: userTimezone,
      })
    }
  }, [botUserId, updateUserTimezone])
}

export default useSaveUserTimezone
