import { useMutation, UseMutationOptions } from 'react-query'
import { ApiKeyNameEnum } from '@enums/api'
import { AxiosError } from 'axios'
import { botatoApiService } from '@services/api/apiService'
import {
  TypeBotUser,
  TypeUpdateBotUserTimezonePayload,
} from '@customTypes/botUser'

/*****************************************
 Bot User Timezone
 ****************************************/

type TypeUpdateBotUserTimezoneOptions = UseMutationOptions<
  TypeBotUser,
  AxiosError,
  TypeUpdateBotUserTimezonePayload
>

const updateBotUserTimezone = async (
  values: TypeUpdateBotUserTimezonePayload,
): Promise<TypeBotUser> => {
  try {
    const { botUserId, ...rest } = values
    const response = await botatoApiService.patchUrl(
      ApiKeyNameEnum.bot_user,
      botUserId + '/timezone/',
      rest,
    )
    return response.data
  } catch (err) {
    throw err
  }
}

export const useUpdateBotUserTimezone = (
  options?: TypeUpdateBotUserTimezoneOptions,
) => {
  return useMutation(updateBotUserTimezone, {
    ...options,
  })
}
