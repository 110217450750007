import React from 'react'
import { TypeMessage } from '@customTypes/radio'

interface Props {
  message: TypeMessage
}

const Message: React.FC<Props> = ({ message }) => {
  const messageText = message.caption || message.text

  return (
    <div className='flex flex-col flex-shrink-0 gap-3 rounded-md p-3 main-bg overflow-hidden'>
      <div className='w-[300px] self-center'>
        {message.photo && (
          <img
            src={message.photo}
            alt='message-media'
            className='w-full h-full'
          />
        )}
        {message.video && (
          <video
            src={message.video}
            muted
            autoPlay={false}
            controls
            className='w-full h-full'
          />
        )}
        {message.audio && (
          <audio
            src={message.audio}
            autoPlay={false}
            controls
            className='w-full h-full'
          />
        )}
        {message.voice && (
          <audio
            src={message.voice}
            autoPlay={false}
            controls
            className='w-full h-full'
          />
        )}
      </div>
      {messageText && (
        <p
          dangerouslySetInnerHTML={{
            __html: messageText.replace(/\n/g, '<br/>'),
          }}
        ></p>
      )}
    </div>
  )
}

export default Message
