import {
  TypeMediaFileType,
  TypePreUploadMediaPayload,
} from '@customTypes/media'
import { MediaFileTypeEnum } from '@enums/media'
import {
  ACCEPTED_AUDIO_FILE_FORMATS,
  ACCEPTED_DOCUMENT_FILE_FORMATS,
  ACCEPTED_FILE_FORMATS,
  ACCEPTED_IMAGE_FILE_FORMATS,
  ACCEPTED_VIDEO_FILE_FORMATS,
  ALL_SUFFIX_TYPES,
} from '@const/media'

export const getPreUploadData = (file: File): TypePreUploadMediaPayload => {
  const fileType = getFileTypeFromMIME(file.type.split('/')[0])
  const contentType = file.type
  const suffix = '.' + getFileSuffix(removeFileExtension(file.name), fileType)

  return {
    fileType,
    contentType,
    suffix,
  }
}

export const removeFileExtension = (filename: any): string => {
  return filename.replace(/\.[^/.]+$/, '')
}

export const getFileTypeFromMIME = (mimeType: string): TypeMediaFileType => {
  const format = mimeType.split('/')[0]

  switch (format) {
    case 'image':
      return MediaFileTypeEnum.Image
    case 'video':
      return MediaFileTypeEnum.Video
    case 'audio':
      return MediaFileTypeEnum.Audio
    case 'text':
    case 'application':
      return MediaFileTypeEnum.Document
    default:
      return MediaFileTypeEnum.Image
  }
}

export const getFileSuffix = (
  filename: string,
  mediaFileType: TypeMediaFileType,
): string => {
  if (!filename) {
    return getSuffixByMime(mediaFileType)
  }

  const ext: string = filename.substring(
    filename.lastIndexOf('.') + 1,
    filename.length,
  )
  if (ALL_SUFFIX_TYPES.indexOf(ext.toLowerCase()) > -1) {
    return ext
  } else {
    return getSuffixByMime(mediaFileType)
  }
}

export const getSuffixByMime = (mimeType: TypeMediaFileType) => {
  switch (mimeType) {
    case MediaFileTypeEnum.Image:
      return 'jpg'
    case MediaFileTypeEnum.Video:
      return 'mp4'
    case MediaFileTypeEnum.Audio:
      return 'aac'
    case MediaFileTypeEnum.Document:
      return 'pdf'
    default:
      return ''
  }
}

export const getAcceptedFileFormats = (
  fileType?: TypeMediaFileType,
): string => {
  let accepted_file_formats: string[]
  switch (fileType) {
    case MediaFileTypeEnum.Image:
      accepted_file_formats = ACCEPTED_IMAGE_FILE_FORMATS
      break
    case MediaFileTypeEnum.Video:
      accepted_file_formats = ACCEPTED_VIDEO_FILE_FORMATS
      break
    case MediaFileTypeEnum.Audio:
      accepted_file_formats = ACCEPTED_AUDIO_FILE_FORMATS
      break
    case MediaFileTypeEnum.Document:
      accepted_file_formats = ACCEPTED_DOCUMENT_FILE_FORMATS
      break
    default:
      accepted_file_formats = ACCEPTED_FILE_FORMATS
  }
  return accepted_file_formats.toString()
}

export const uploadMedia = (
  file: File,
  url: string,
  progressCallback?: (p: number) => void,
) =>
  new Promise(async resolve => {
    const fileData: any = {
      uri: url,
      type:
        getFileTypeFromMIME(file.type.split('/')[0]) ||
        'application/octet-stream',
      name: file.name,
    }

    console.log('fileData', fileData)
    const xhr = new XMLHttpRequest()

    xhr.upload.addEventListener(
      'progress',
      (e: any) => {
        progressCallback &&
          progressCallback(Math.ceil((e.loaded / e.total) * 100))
      },
      false,
    )

    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        fileData.status = Boolean(xhr.status === 200)
        if (xhr.status !== 200) {
          fileData.error = 'upload failed'
        }
        resolve(fileData)
      }
    }
    xhr.open('PUT', url || '')
    // xhr.setRequestHeader('X-Amz-ACL', '-read')
    xhr.setRequestHeader('Content-Type', file.type)
    xhr.send(file)
  })

export const extractBaseUrl = (url: string): string => {
  const [baseUrl] = url.split('?')
  return baseUrl
}
