import React, { useCallback, useContext, useMemo, useState } from 'react'
import {
  TypeBaseRadioHost,
  TypeRadioCategory,
  TypeRadioUserType,
} from '@customTypes/radio'
import Avatar from '@components/avatar/Avatar'
import classNames from 'classnames'
import { useSearchParams } from 'react-router-dom'
import { RadioUserTypeEnum } from '@enums/radio'
import { ReactComponent as CheckIcon } from '@assets/images/common/done.svg'
import { BIO_CHARACTER_LIMIT } from '@const/radio'
import { RadioCategoriesContext } from '@context/radioCategories'

interface Props {
  host: TypeBaseRadioHost
  isSelected: boolean
  onSelect?: (selectedHostId: string) => void
  onUnselect?: (selectedHostId: string) => void
  selectable?: boolean
  selectedCategoryIds?: number[]
}

const RadioHost: React.FC<Props> = ({
  host,
  isSelected,
  onSelect = () => {},
  onUnselect = () => {},
  selectable = true,
  selectedCategoryIds = [],
}) => {
  const [searchParams] = useSearchParams()
  const radioCategoriesMap = useContext(RadioCategoriesContext)
  const userType = searchParams.get('user_type') as TypeRadioUserType

  const [isBioExpanded, setIsBioExpanded] = useState<boolean>(false)

  const toggleBio = useCallback(event => {
    event.stopPropagation()
    setIsBioExpanded(prev => !prev)
  }, [])

  const trimmedBio = useMemo(() => {
    if (!host.bio) {
      return ''
    }
    if (host.bio.length <= BIO_CHARACTER_LIMIT || isBioExpanded) {
      return host.bio
    }
    return `${host.bio.substring(0, BIO_CHARACTER_LIMIT).trim()}...`
  }, [host.bio, isBioExpanded])

  const toggleSelect = useCallback(() => {
    if (isSelected && selectable) {
      onUnselect(host.id)
    } else {
      onSelect(host.id)
    }
  }, [host.id, isSelected, onSelect, onUnselect, selectable])

  const name = useMemo<string>(() => {
    return host.postCount && userType !== RadioUserTypeEnum.Listener
      ? `${host.name} (${host.postCount})`
      : host.name
  }, [host.name, host.postCount, userType])

  const relevantCategories = useMemo<TypeRadioCategory[] | null>(() => {
    if (!radioCategoriesMap) {
      return null
    }
    return host.mostRelevantCategoryIds
      .map(categoryId => radioCategoriesMap[categoryId])
      .filter(category => category !== undefined)
  }, [host.mostRelevantCategoryIds, radioCategoriesMap])

  const bioClasses = useMemo(
    () =>
      classNames(
        'text-sm font-normal leading-tight cursor-pointer transition-[max-height] duration-500 ease-in-out overflow-hidden',
        {
          'max-h-96': isBioExpanded,
          'max-h-[4.5rem]': !isBioExpanded,
        },
      ),
    [isBioExpanded],
  )

  return (
    <div
      className={classNames(
        'w-full p-2.5 rounded-2xl transition cursor-pointer border',
        isSelected && selectable && 'border-2 border-custom-teal',
      )}
      onClick={toggleSelect}
    >
      <div className='flex flex-col gap-2'>
        <div className='flex gap-2'>
          <Avatar name={host.name} imageUrl={host.profileImage} />
          <div className='flex flex-col gap-1.5 flex-1'>
            <div className='flex space-between items-center self-stretch'>
              <p className="flex-1 text-base font-bold font-['Montserrat'] leading-none">
                {' '}
                {name}
              </p>
              {selectable && (
                <div
                  className={classNames(
                    'w-6 h-6 rounded-full border cursor-pointer flex items-center justify-center',
                    isSelected && 'bg-custom-teal',
                  )}
                >
                  <CheckIcon
                    className={classNames('w-[17px]', !isSelected && 'hidden')}
                  />
                </div>
              )}
            </div>
            <p className={bioClasses} onClick={toggleBio}>
              {isBioExpanded ? host.bio : trimmedBio}
            </p>
          </div>
        </div>
        {relevantCategories?.length ? (
          <div className='flex items-center flex-wrap gap-x-5'>
            {relevantCategories.map(category => (
              <p
                key={category.id}
                className={classNames(
                  'text-[11px] uppercase font-bold',
                  selectedCategoryIds.includes(category.id)
                    ? 'text-custom-radio-pink'
                    : 'text-custom-radio-yellow',
                )}
              >
                {category.name}
              </p>
            ))}
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default RadioHost
