import {
  TypeRadioCategoryContentType,
  TypeRadioHostRole,
} from '@customTypes/radio'
import { TypeOptions } from '@customTypes/common'
import { _t } from '@locales/index'
import { RadioCategoryContentTypeEnum, RadioHostRoleEnum } from '@enums/radio'

export const RADIO_CATEGORY_CONTENT_TYPE_OPTIONS: TypeOptions<TypeRadioCategoryContentType> =
  [
    {
      label: _t('radioCategoryContentTypeOption1'),
      value: RadioCategoryContentTypeEnum.Dynamic,
    },
    {
      label: _t('radioCategoryContentTypeOption2'),
      value: RadioCategoryContentTypeEnum.Static,
    },
  ]

export const RADIO_HOST_ROLE_OPTIONS: TypeOptions<TypeRadioHostRole> = [
  {
    label: _t('radioHostRoleOption1'),
    value: RadioHostRoleEnum.Owner,
  },
  {
    label: _t('radioHostRoleOption2'),
    value: RadioHostRoleEnum.Admin,
  },
  {
    label: _t('radioHostRoleOption3'),
    value: RadioHostRoleEnum.Host,
  },
  {
    label: _t('radioHostRoleOption4'),
    value: RadioHostRoleEnum.Partner,
  },
]

export const BIO_CHARACTER_LIMIT: number = 100
