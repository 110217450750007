import React, { useCallback, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { TypeManageCategoriesScreen } from '@customTypes/radio'
import { ManageCategoriesScreenEnum } from '@enums/radio'
import { telegramService } from '@services/telegram/telegramService'
import UpdateCategories from '@containers/radio/pages/UpdateCategories'
import UpdateCategory from '@containers/radio/pages/UpdateCategory'
import Posts from '@containers/radio/pages/Posts'

const ManageCategories: React.FC = () => {
  const [searchParams] = useSearchParams()
  const radioId = searchParams.get('radio_id') || ''

  const [selectedCategoryId, setSelectedCategoryId] = useState<number | null>(
    null,
  )
  const [currentScreen, setCurrentScreen] =
    useState<TypeManageCategoriesScreen>(ManageCategoriesScreenEnum.Categories)

  const handleSubmit = useCallback(() => {
    telegramService.close(true)
  }, [])

  const handleSelectCategory = useCallback((categoryId: number) => {
    setSelectedCategoryId(categoryId)
    setCurrentScreen(ManageCategoriesScreenEnum.UpdateCategory)
  }, [])

  const handleSubmitCategory = useCallback(() => {
    setCurrentScreen(ManageCategoriesScreenEnum.Categories)
    setSelectedCategoryId(null)
  }, [])

  const renderScreen = useMemo(() => {
    switch (currentScreen) {
      case ManageCategoriesScreenEnum.Categories:
        return (
          <UpdateCategories
            radioId={radioId}
            onSubmit={handleSubmit}
            onSelectCategory={handleSelectCategory}
            onAddNew={() =>
              setCurrentScreen(ManageCategoriesScreenEnum.UpdateCategory)
            }
          />
        )
      case ManageCategoriesScreenEnum.UpdateCategory:
        return (
          <UpdateCategory
            selectedCategoryId={selectedCategoryId}
            onSubmit={handleSubmitCategory}
            onClickViewPosts={() =>
              setCurrentScreen(ManageCategoriesScreenEnum.ViewPosts)
            }
            radioId={radioId}
          />
        )
      case ManageCategoriesScreenEnum.ViewPosts:
        return (
          <Posts
            categoryId={selectedCategoryId}
            onClickBack={() =>
              setCurrentScreen(ManageCategoriesScreenEnum.UpdateCategory)
            }
          />
        )
      default:
        return null
    }
  }, [
    currentScreen,
    handleSelectCategory,
    handleSubmit,
    handleSubmitCategory,
    radioId,
    selectedCategoryId,
  ])

  return <div className='flex flex-col h-screen w-screen'>{renderScreen}</div>
}

export default ManageCategories
