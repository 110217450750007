import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { telegramService } from '@services/telegram/telegramService'
import { _t } from '@locales/index'
import {
  TypeEditHostCategory,
  TypeRadioCategory,
  TypeRadioHost,
  TypeRadioHostFormValues,
  TypeUpdateRadioHostPayload,
} from '@customTypes/radio'
import { EditHostCategoryEnum, RadioHostFormEnum } from '@enums/radio'
import InputField from '@components/form/InputField'
import { Field, useFormikContext } from 'formik'
import {
  useCreateRadioHost,
  useDeleteRadioHost,
  useUpdateRadioHost,
} from '@api/queries/radio/hosts'
import { ReactComponent as TrashIcon } from '@assets/images/common/trash.svg'
import TextareaField from '@components/form/TextareaField'
import { TypeClosePopupParams } from '@customTypes/common'
import { PopupButtonIdEnum } from '@enums/common'
import { ServerStateKeysEnum } from '@enums/serverState'
import { useQueryClient } from 'react-query'
import { RadioCategoriesContext } from '@context/radioCategories'
import HostCategories from '@containers/radio/components/HostCategories'
import SelectHostCategories from '@containers/radio/components/SelectHostCategories'
import Loader from '@components/loader/Loader'
import useCopyToClipboard from '@hooks/common/useCopyToClipboard'
import ImageUpload from '@components/form/ImageUpload'

interface Props {
  selectedHost?: TypeRadioHost
  onSubmit: () => void
  radioId: string
  onClickViewPosts: () => void
}

const UpdateHostInner: React.FC<Props> = ({
  selectedHost,
  onSubmit,
  radioId,
  onClickViewPosts,
}) => {
  const queryClient = useQueryClient()
  const radioCategoriesMap = useContext(RadioCategoriesContext)
  const { copyFn: copyInviteCode, copiedToClipboard: inviteLinkCopied } =
    useCopyToClipboard()
  const { copyFn: copyAccessLink, copiedToClipboard: accessLinkCopied } =
    useCopyToClipboard()
  const { copyFn: copyDiscoveryLink, copiedToClipboard: discoveryLinkCopied } =
    useCopyToClipboard()
  const { values, isValid, dirty } = useFormikContext<TypeRadioHostFormValues>()

  const [editMode, setEditMode] = useState<TypeEditHostCategory | undefined>()
  const [isMediaUploading, setIsMediaUploading] = useState<boolean>(false)

  const { mutate: updateRadioHost, isLoading: isUpdating } =
    useUpdateRadioHost()
  const { mutate: createRadioHost, isLoading: isCreating } =
    useCreateRadioHost()
  const { mutate: deleteRadioHost, isLoading: isDeleting } =
    useDeleteRadioHost()

  const apiCallback = useCallback(() => {
    queryClient.invalidateQueries(ServerStateKeysEnum.RadioHosts).then()
    queryClient.invalidateQueries(ServerStateKeysEnum.RadioHost).then()
    onSubmit()
  }, [onSubmit, queryClient])

  const relevantCategories = useMemo<TypeRadioCategory[] | null>(() => {
    if (!radioCategoriesMap) {
      return null
    }
    return values[RadioHostFormEnum.MostRelevantCategoryIds]
      .map(categoryId => radioCategoriesMap[categoryId])
      .filter(category => category !== undefined)
  }, [radioCategoriesMap, values])

  const additionalCategories = useMemo<TypeRadioCategory[] | null>(() => {
    if (!radioCategoriesMap) {
      return null
    }
    return values[RadioHostFormEnum.AdditionalCategoryIds]
      .map(categoryId => radioCategoriesMap[categoryId])
      .filter(category => category !== undefined)
  }, [radioCategoriesMap, values])

  const mainButtonCallback = useCallback(() => {
    if (isValid) {
      const hostData = {
        name: values[RadioHostFormEnum.Name],
        bio: values[RadioHostFormEnum.Bio],
        role: values[RadioHostFormEnum.Role],
        profileImage: values[RadioHostFormEnum.ProfileImage],
        englishName: values[RadioHostFormEnum.EnglishName],
        previewImage: values[RadioHostFormEnum.PreviewImage],
        welcomeMessage: values[RadioHostFormEnum.WelcomeMessage],
        mostRelevantCategoryIds:
          values[RadioHostFormEnum.MostRelevantCategoryIds],
        additionalCategoryIds: values[RadioHostFormEnum.AdditionalCategoryIds],
        isVisibleToConsumers: values[RadioHostFormEnum.IsVisibleToConsumers],
      }

      if (selectedHost) {
        const radioCategoryPayload: TypeUpdateRadioHostPayload = {
          id: selectedHost.id.toString(),
          ...hostData,
        }
        updateRadioHost(radioCategoryPayload, {
          onSuccess: apiCallback,
        })
      } else {
        createRadioHost(
          {
            radioId: radioId,
            ...hostData,
          },
          {
            onSuccess: apiCallback,
          },
        )
      }
    }
  }, [
    createRadioHost,
    isValid,
    apiCallback,
    radioId,
    selectedHost,
    updateRadioHost,
    values,
  ])

  const deleteAndGoBack = useCallback(() => {
    if (selectedHost) {
      deleteRadioHost(selectedHost.id, {
        onSuccess: apiCallback,
      })
    }
  }, [deleteRadioHost, apiCallback, selectedHost])

  const handleClosePopup = useCallback(
    (params: TypeClosePopupParams) => {
      if (params.button_id === PopupButtonIdEnum.Delete) {
        deleteAndGoBack()
      }
    },
    [deleteAndGoBack],
  )

  const showConfirmationPopup = useCallback(() => {
    telegramService.showDeleteConfirmationPopup()
  }, [])

  const clearEditMode = useCallback(() => {
    setEditMode(undefined)
  }, [])

  useEffect(() => {
    telegramService.mainButton.show()
    telegramService.backButton.show()
    telegramService.webApp.onEvent('popupClosed', handleClosePopup)
    // if not edit mode
    if (!editMode) {
      telegramService.mainButton.onClick(mainButtonCallback)
      telegramService.backButton.onClick(onSubmit)
    } else {
      telegramService.backButton.offClick(onSubmit)
      telegramService.mainButton.offClick(mainButtonCallback)
      telegramService.backButton.onClick(clearEditMode)
    }
    return () => {
      telegramService.mainButton.offClick(mainButtonCallback)
      telegramService.backButton.hide().offClick(onSubmit)
      telegramService.backButton.hide().offClick(clearEditMode)
      telegramService.webApp.offEvent('popupClosed', handleClosePopup)
    }
  }, [clearEditMode, editMode, handleClosePopup, mainButtonCallback, onSubmit])

  useEffect(() => {
    if (selectedHost) {
      telegramService.setMainButtonText(_t('update'))
    } else {
      telegramService.setMainButtonText(_t('addNew'))
    }
  }, [selectedHost])

  useEffect(() => {
    if (isValid && dirty) {
      telegramService.enableMainButton()
    } else {
      telegramService.disableMainButton()
    }
  }, [dirty, isValid])

  if (editMode) {
    let fieldName = RadioHostFormEnum.MostRelevantCategoryIds
    let disabledCategories = values[RadioHostFormEnum.AdditionalCategoryIds]

    if (editMode === EditHostCategoryEnum.Additional) {
      fieldName = RadioHostFormEnum.AdditionalCategoryIds
      disabledCategories = values[RadioHostFormEnum.MostRelevantCategoryIds]
    }

    return (
      <SelectHostCategories
        radioId={radioId}
        fieldName={fieldName}
        onClickNext={() => setEditMode(undefined)}
        editMode={editMode}
        disabledCategoryIds={disabledCategories}
      />
    )
  }

  return (
    <>
      <div className='flex flex-col items-center gap-3 no-scrollbar'>
        <h1 className='h5 text-center'>{_t('updateHostFormTitle')}</h1>
        <div className='flex flex-col gap-14 w-full'>
          <div className='flex flex-col gap-5'>
            <ImageUpload
              label={_t('updateHostProfileImageLabel')}
              name={RadioHostFormEnum.ProfileImage}
              id={RadioHostFormEnum.ProfileImage}
              fontWeightClass='font-bold'
              textClass='text-lg'
              labelClass='text-custom-teal font-semibold'
              setIsMediaUploading={setIsMediaUploading}
            />
            <InputField
              label={_t('updateHostNameLabel')}
              name={RadioHostFormEnum.Name}
              id={RadioHostFormEnum.Name}
              fontWeightClass='font-bold'
              textClass='text-lg'
              labelClass='text-custom-teal font-semibold'
            />
            <InputField
              label={_t('updateHostEnglishNameLabel')}
              name={RadioHostFormEnum.EnglishName}
              id={RadioHostFormEnum.EnglishName}
              fontWeightClass='font-bold'
              textClass='text-lg'
              labelClass='text-custom-teal font-semibold'
            />
            <TextareaField
              label={_t('updateHostBioLabel')}
              name={RadioHostFormEnum.Bio}
              id={RadioHostFormEnum.Bio}
              fontWeightClass='font-normal'
              maxLength={250}
              labelClass='text-custom-teal font-semibold'
            />
            <ImageUpload
              label={_t('updateHostPreviewImageLabel')}
              name={RadioHostFormEnum.PreviewImage}
              id={RadioHostFormEnum.PreviewImage}
              fontWeightClass='font-bold'
              textClass='text-lg'
              labelClass='text-custom-teal font-semibold'
              setIsMediaUploading={setIsMediaUploading}
            />
            <TextareaField
              label={_t('updateHostWelcomeMessageLabel')}
              name={RadioHostFormEnum.WelcomeMessage}
              id={RadioHostFormEnum.WelcomeMessage}
              fontWeightClass='font-normal'
              maxLength={250}
              labelClass='text-custom-teal font-semibold'
            />
            <div className='w-full flex'>
              <label className='body2 capitalize text-custom-teal font-semibold flex gap-2 justify-center items-center'>
                <Field
                  type='checkbox'
                  name={RadioHostFormEnum.IsVisibleToConsumers}
                />
                {_t('updateHostShowToConsumersLabel')}
              </label>
            </div>
            <HostCategories
              label={_t('updateHostMostRelevantCategoriesLabel')}
              categories={relevantCategories}
              onClickEdit={() => setEditMode(EditHostCategoryEnum.MostRelevant)}
            />
            <HostCategories
              label={_t('updateHostAdditionalCategoriesLabel')}
              categories={additionalCategories}
              onClickEdit={() => setEditMode(EditHostCategoryEnum.Additional)}
            />
          </div>
          {selectedHost && (
            <div className='flex flex-col gap-3'>
              <button
                className='btn btn-outlined-pink rounded-full'
                onClick={onClickViewPosts}
              >
                <p>{_t('showPostHistory')}</p>
              </button>
              <button
                className='btn btn-outlined-teal rounded-full'
                onClick={() => copyInviteCode(selectedHost?.inviteLink)}
              >
                <p>
                  {inviteLinkCopied
                    ? _t('copiedToClipboard')
                    : _t('radioHostCopyInviteLink')}
                </p>
              </button>
              <button
                className='btn btn-outlined-yellow rounded-full'
                onClick={() => copyAccessLink(selectedHost?.securityLink)}
              >
                <p>
                  {accessLinkCopied
                    ? _t('copiedToClipboard')
                    : _t('radioHostCopyAccessLink')}
                </p>
              </button>
              <button
                className='btn btn-outlined-teal rounded-full'
                onClick={() => copyDiscoveryLink(selectedHost?.discoverLink)}
              >
                <p>
                  {discoveryLinkCopied
                    ? _t('copiedToClipboard')
                    : _t('radioHostCopyDiscoveryLink')}
                </p>
              </button>
              <button
                className='btn btn-text text-custom-error-red'
                onClick={showConfirmationPopup}
              >
                <TrashIcon className='fill-custom-error-red w-4 h-4' />
                <p>{_t('delete')}</p>
              </button>
            </div>
          )}
        </div>
        <Loader
          visible={isDeleting || isUpdating || isCreating || isMediaUploading}
          fullWidth
        />
      </div>
    </>
  )
}

export default UpdateHostInner
