import React, { useCallback, useRef } from 'react'
import classNames from 'classnames'
import { ErrorMessage, useField } from 'formik'
import { usePreUploadMedia, useSetMediaPublic } from '@api/queries/media'
import {
  extractBaseUrl,
  getAcceptedFileFormats,
  getPreUploadData,
  uploadMedia,
} from '@common/media'
import { MediaFileTypeEnum } from '@enums/media'
import { _t } from '@locales/index'

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string
  id: string
  textClass?: string
  fontWeightClass?: string
  labelClass?: string
  label?: string
  setIsMediaUploading?: (isLoading: boolean) => void
}

const ImageUpload: React.FC<Props> = ({
  className,
  textClass,
  fontWeightClass,
  labelClass,
  label,
  setIsMediaUploading,
  ...props
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [, meta, helpers] = useField(props)

  const { mutateAsync: setMediaPublic } = useSetMediaPublic()
  const { mutateAsync: preUploadMedia } = usePreUploadMedia({
    onMutate: () => {
      setIsMediaUploading && setIsMediaUploading(true)
    },
  })

  const handleFileChange = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0]
      if (file) {
        await preUploadMedia(getPreUploadData(file), {
          onSuccess: async response => {
            await uploadMedia(file, response.url)
            await setMediaPublic(
              { url: extractBaseUrl(response?.url) },
              {
                onSuccess: async () => {
                  await helpers.setValue(extractBaseUrl(response.url))
                  setIsMediaUploading && setIsMediaUploading(false)
                },
              },
            )
          },
        })
      }
    },
    [preUploadMedia, setMediaPublic, helpers, setIsMediaUploading],
  )

  const handleImageUploadClick = useCallback(() => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }, [])

  return (
    <div className='w-full flex flex-col gap-2'>
      {label && (
        <label
          htmlFor={props.id}
          className={classNames('body2 capitalize', labelClass)}
        >
          {label}
        </label>
      )}
      <div
        className='w-20 h-24 rounded-xl border flex justify-center items-center self-center bg-center bg-cover'
        onClick={handleImageUploadClick}
        style={{
          backgroundImage: meta.value ? `url('${meta.value}')` : undefined,
        }}
      >
        {!meta.value && (
          <p className='text-center text-custom-radio-pink'>
            {_t('updateHostAddPhoto')}
          </p>
        )}
        <input
          type='file'
          id={props.id}
          name={props.name}
          accept={getAcceptedFileFormats(MediaFileTypeEnum.Image)}
          onChange={handleFileChange}
          className='hidden'
          ref={fileInputRef}
        />
      </div>
      <ErrorMessage
        name={props.name}
        component='p'
        className='text-custom-error-red caption mt-2'
      />
    </div>
  )
}

export default ImageUpload
