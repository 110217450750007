import React, { useCallback, useEffect, useMemo } from 'react'
import { useGetRadioCategories } from '@api/queries/radio/categories'
import Loader from '@components/loader/Loader'
import { TypeOptions } from '@customTypes/common'
import { createTypeOptions } from '@common/index'
import Chip from '@components/chip/Chip'
import { telegramService } from '@services/telegram/telegramService'
import { _t } from '@locales/index'
import { ErrorMessage, useField } from 'formik'
import { TypeEditHostCategory } from '@customTypes/radio'
import { EditHostCategoryEnum } from '@enums/radio'

interface Props {
  radioId: string
  fieldName: string
  onClickNext: () => void
  editMode: TypeEditHostCategory | undefined
  disabledCategoryIds: number[]
}

const SelectHostCategories: React.FC<Props> = ({
  radioId,
  fieldName,
  editMode,
  onClickNext = () => {},
  disabledCategoryIds,
}) => {
  const [, meta, helpers] = useField<number[]>(fieldName)

  const { data: radioCategoriesResponse, isLoading } = useGetRadioCategories(
    radioId,
    {
      enabled: Boolean(radioId),
    },
  )

  const categoryOptions = useMemo<TypeOptions<number> | null>(() => {
    if (!radioCategoriesResponse) {
      return null
    }
    return createTypeOptions(
      radioCategoriesResponse.radioCategories,
      'name',
      'id',
    )
  }, [radioCategoriesResponse])

  const handleSelectCategory = useCallback(
    (categoryId: number) => {
      helpers.setValue([...meta.value, categoryId]).then()
    },
    [helpers, meta.value],
  )

  const handleUnselectCategory = useCallback(
    (categoryId: number) => {
      helpers.setValue(meta.value.filter(id => id !== categoryId)).then()
    },
    [helpers, meta.value],
  )

  useEffect(() => {
    if (
      meta.error ||
      (editMode === EditHostCategoryEnum.MostRelevant &&
        meta.value.length === 0)
    ) {
      telegramService.disableMainButton()
    } else {
      telegramService.mainButton.show().onClick(onClickNext)
      telegramService.enableMainButton()
      telegramService.setMainButtonText(_t('continue'))
    }

    return () => {
      telegramService.mainButton.offClick(onClickNext)
    }
  }, [editMode, meta.error, meta.value.length, onClickNext])

  return (
    <div className='flex flex-col items-center gap-3'>
      <h1 className='h5 text-center'>{_t('chooseRadioCategoriesTitle')}</h1>
      <ErrorMessage
        name={fieldName}
        component='p'
        className='text-custom-error-red caption mt-2'
      />
      <div className='flex flex-wrap gap-2 items-center'>
        {categoryOptions &&
          categoryOptions.map(categoryOption => {
            const isSelected = meta.value.some(
              selectedCategoryId => selectedCategoryId === categoryOption.value,
            )
            const isDisabled = disabledCategoryIds.includes(
              categoryOption.value,
            )
            return (
              <Chip<number>
                key={categoryOption.value}
                data={categoryOption}
                isSelected={isSelected}
                onSelect={handleSelectCategory}
                onUnselect={handleUnselectCategory}
                disabled={isDisabled}
              />
            )
          })}
        <Loader visible={isLoading} fullWidth />
      </div>
    </div>
  )
}

export default SelectHostCategories
