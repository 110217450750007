import { RouteObject } from 'react-router-dom'
import { PATH } from '@routes/paths'
import Payment from '@containers/payment/Payment'
import ChooseRadioData from '@containers/radio/ChooseRadioData'
import ManageCategories from '@containers/radio/ManageCategories'
import ManageHosts from '@containers/radio/ManageHosts'
import ChooseCategories from '@containers/radio/ChooseCategories'
import ChooseHosts from '@containers/radio/ChooseHosts'
import HostScheduledPosts from '@containers/radio/HostScheduledPosts'

export const MainRoutes: RouteObject[] = [
  {
    path: PATH.payment,
    element: <Payment />,
  },
  {
    path: PATH.radioSelectData,
    element: <ChooseRadioData />,
  },
  {
    path: PATH.radioSelectCategories,
    element: <ChooseCategories />,
  },
  {
    path: PATH.radioSelectHosts,
    element: <ChooseHosts />,
  },
  {
    path: PATH.radioHostScheduledPosts,
    element: <HostScheduledPosts />,
  },
  {
    path: PATH.radioManageCategories,
    element: <ManageCategories />,
  },
  {
    path: PATH.radioManageHosts,
    element: <ManageHosts />,
  },
]
