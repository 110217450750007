import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from 'react-query'
import { ServerStateKeysEnum } from '@enums/serverState'
import { botatoApiService } from '@services/api/apiService'
import { ApiKeyNameEnum } from '@enums/api'
import { AxiosError } from 'axios'
import {
  TypeGetRadioMessageRelatedDataResponse,
  TypeRadioMessagesResponse,
  TypeUpdateRadioMessageRelatedDataPayload,
} from '@customTypes/radio'
import snakecaseKeys from 'snakecase-keys'

/*****************************************
 Get By Category
 ****************************************/
type TypeRadioMessagesByCategoryOptions = UseQueryOptions<
  TypeRadioMessagesResponse,
  AxiosError,
  TypeRadioMessagesResponse,
  any
>

const getRadioMessagesByCategory = async (
  radio_category_id: string,
): Promise<TypeRadioMessagesResponse> => {
  try {
    const response = await botatoApiService.get(
      ApiKeyNameEnum.radio_categories,
      `${radio_category_id}/messages/`,
    )
    return response.data
  } catch (err) {
    throw err
  }
}

export const useGetRadioMessagesByCategory = (
  radio_category_id: string,
  options?: TypeRadioMessagesByCategoryOptions,
) => {
  return useQuery(
    [ServerStateKeysEnum.RadioMessages, radio_category_id],
    () => getRadioMessagesByCategory(radio_category_id),
    {
      ...options,
    },
  )
}

/*****************************************
 Get By Host
 ****************************************/
type TypeRadioMessagesByHostOptions = UseQueryOptions<
  TypeRadioMessagesResponse,
  AxiosError,
  TypeRadioMessagesResponse,
  any
>

const getRadioMessagesByHost = async (
  radio_host_id: string,
): Promise<TypeRadioMessagesResponse> => {
  try {
    const response = await botatoApiService.get(
      ApiKeyNameEnum.radio_hosts,
      `${radio_host_id}/messages/`,
    )
    return response.data
  } catch (err) {
    throw err
  }
}

export const useGetRadioMessagesByHost = (
  radio_host_id: string,
  options?: TypeRadioMessagesByHostOptions,
) => {
  return useQuery(
    [ServerStateKeysEnum.RadioMessages, radio_host_id],
    () => getRadioMessagesByHost(radio_host_id),
    {
      retry: 5,
      ...options,
    },
  )
}

/*****************************************
 Get Broadcast Scheduled Messages By Host
 ****************************************/
type TypeRadioScheduledMessagesByHostOptions = UseQueryOptions<
  TypeRadioMessagesResponse,
  AxiosError,
  TypeRadioMessagesResponse,
  any
>

const getScheduledRadioMessagesByHost = async (
  radio_host_id: string,
): Promise<TypeRadioMessagesResponse> => {
  try {
    const response = await botatoApiService.get(
      ApiKeyNameEnum.radio_hosts,
      `${radio_host_id}/scheduled-messages/`,
    )
    return response.data
  } catch (err) {
    throw err
  }
}

export const useGetScheduledRadioMessagesByHost = (
  radio_host_id: string,
  options?: TypeRadioScheduledMessagesByHostOptions,
) => {
  return useQuery(
    [ServerStateKeysEnum.RadioScheduledMessages, radio_host_id],
    () => getScheduledRadioMessagesByHost(radio_host_id),
    {
      retry: 5,
      ...options,
    },
  )
}

/*****************************************
 Delete
 ****************************************/
type TypeDeleteRadioMessageOptions = UseMutationOptions<string, AxiosError, any>

const deleteRadioMessage = async (radioMessageId: string): Promise<string> => {
  try {
    const response = await botatoApiService.deleteUrl(
      ApiKeyNameEnum.radio_message,
      radioMessageId + '/',
    )
    return response.data
  } catch (err) {
    throw err
  }
}

export const useDeleteRadioMessage = (
  options?: TypeDeleteRadioMessageOptions,
) => {
  return useMutation(deleteRadioMessage, {
    ...options,
  })
}

/*****************************************
 Get Related Data
 ****************************************/
type TypeGetRadioMessageRelatedDataOptions = UseQueryOptions<
  TypeGetRadioMessageRelatedDataResponse,
  AxiosError,
  TypeGetRadioMessageRelatedDataResponse,
  any
>

const getRadioMessageRelatedData = async (
  botSchedulerId: string,
): Promise<TypeGetRadioMessageRelatedDataResponse> => {
  try {
    const response = await botatoApiService.get(
      ApiKeyNameEnum.radio_message,
      `${botSchedulerId}/related-data/`,
    )
    return response.data
  } catch (err) {
    throw err
  }
}

export const useGetRadioMessageRelatedData = (
  botSchedulerId: string,
  options?: TypeGetRadioMessageRelatedDataOptions,
) => {
  return useQuery(
    [ServerStateKeysEnum.RadioMessageRelatedData, botSchedulerId],
    () => getRadioMessageRelatedData(botSchedulerId),
    {
      ...options,
    },
  )
}

/*****************************************
 Update Related Data
 ****************************************/
type TypeUpdateRadioMessageRelatedDataOptions = UseMutationOptions<
  TypeUpdateRadioMessageRelatedDataPayload,
  AxiosError,
  TypeUpdateRadioMessageRelatedDataPayload
>

const updateRadioMessageRelatedData = async (
  values: TypeUpdateRadioMessageRelatedDataPayload,
): Promise<TypeUpdateRadioMessageRelatedDataPayload> => {
  try {
    const { botSchedulerId, ...rest } = values
    const response = await botatoApiService.putUrl(
      ApiKeyNameEnum.radio_message,
      botSchedulerId + '/related-data/',
      snakecaseKeys(rest),
    )
    return response.data
  } catch (err) {
    throw err
  }
}

export const useUpdateRadioMessageRelatedData = (
  options?: TypeUpdateRadioMessageRelatedDataOptions,
) => {
  return useMutation(updateRadioMessageRelatedData, {
    ...options,
  })
}
