import React from 'react'
import { TypeOption } from '@customTypes/common'
import { Listbox } from '@headlessui/react'
import { ReactComponent as CheckedIcon } from '@assets/images/common/checked-circle.svg'
import { ReactComponent as UncheckedIcon } from '@assets/images/common/unchecked-circle.svg'

interface Props<T> {
  option: TypeOption<T>
  checkbox?: string
}

const SelectBoxOption = <T,>({ option, checkbox }: Props<T>) => {
  return (
    <Listbox.Option
      className={({
        selected,
      }) => `relative cursor-pointer select-none py-3 px-2.5 pr-12 text-sm font-poppins capitalize flex items-center gap-2.5 bg-theme
        `}
      value={option.value}
    >
      {({ selected }) => (
        <>
          {selected ? (
            checkbox === 'solid' ? (
              <div className='w-4 h-4 rounded-full bg-custom-black'></div>
            ) : (
              <CheckedIcon fill='#202020' />
            )
          ) : checkbox === 'solid' ? (
            <div className='w-4 h-4 rounded-full border border-custom-black'></div>
          ) : (
            <UncheckedIcon fill='#202020' />
          )}
          <span
            className={`block truncate ${
              selected ? 'font-medium' : 'font-normal'
            }`}
          >
            {option.label}
          </span>
        </>
      )}
    </Listbox.Option>
  )
}

export default SelectBoxOption
