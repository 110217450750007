import React, { useEffect, useMemo } from 'react'
import { useGetRadioCategories } from '@api/queries/radio/categories'
import Loader from '@components/loader/Loader'
import { TypeOptions } from '@customTypes/common'
import { createTypeOptions } from '@common/index'
import Chip from '@components/chip/Chip'
import { telegramService } from '@services/telegram/telegramService'
import { _t } from '@locales/index'
import classNames from 'classnames'
import { ReactComponent as Add } from '@assets/images/common/add.svg'
import { useSearchParams } from 'react-router-dom'
import { TypeRadioUserType } from '@customTypes/radio'
import { RadioUserTypeEnum } from '@enums/radio'

interface Props {
  radioId: string
  onSubmit: () => void
  onSelectCategory: (categoryId: number) => void
  onAddNew: () => void
}

const UpdateCategories: React.FC<Props> = ({
  radioId,
  onSubmit,
  onSelectCategory,
  onAddNew,
}) => {
  const [searchParams] = useSearchParams()
  const userType = searchParams.get('user_type') as TypeRadioUserType
  const { data: radioCategoriesResponse, isLoading } = useGetRadioCategories(
    radioId,
    {
      enabled: Boolean(radioId),
    },
  )

  const categoryOptions = useMemo<TypeOptions<number> | null>(() => {
    if (!radioCategoriesResponse) {
      return null
    }
    const categoriesWithPostCountInName =
      radioCategoriesResponse.radioCategories.map(radioCategory => {
        return {
          ...radioCategory,
          name:
            radioCategory.postCount && userType !== RadioUserTypeEnum.Listener
              ? `${radioCategory.name} (${radioCategory.postCount})`
              : radioCategory.name,
        }
      })
    return createTypeOptions(categoriesWithPostCountInName, 'name', 'id')
  }, [radioCategoriesResponse, userType])

  useEffect(() => {
    telegramService.mainButton.show().onClick(onSubmit)
    telegramService.setMainButtonText(_t('done'))
    return () => {
      telegramService.mainButton.offClick(onSubmit)
    }
  }, [onSubmit])

  return (
    <div className='flex flex-col items-center gap-3 p-5'>
      <h1 className='h4 text-center'>{_t('updateCategoriesTitle')}</h1>
      <div className='flex flex-wrap gap-2 items-center'>
        <div
          className='flex gap-1.5 items-center border rounded-full py-1 transition cursor-pointer px-6 border-custom-pink custom-shadow-2'
          onClick={onAddNew}
        >
          <Add className='fill-custom-pink w-3 h-3 cursor-pointer' />
          <p className={classNames('body3 transition-colors text-custom-pink')}>
            {_t('addNew')}
          </p>
        </div>
        {categoryOptions &&
          categoryOptions.map(categoryOption => {
            return (
              <Chip<number>
                key={categoryOption.value}
                data={categoryOption}
                isSelected={false}
                selectable={false}
                onSelect={onSelectCategory}
              />
            )
          })}
      </div>
      <Loader visible={isLoading} fullWidth />
    </div>
  )
}

export default UpdateCategories
