import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  useGetRadioMessagesByCategory,
  useGetRadioMessagesByHost,
} from '@api/queries/radio/messages'
import { telegramService } from '@services/telegram/telegramService'
import { _t } from '@locales/index'
import Post from '@containers/radio/components/Post'
import { TypeRadioMessage, TypeRadioMessagesResponse } from '@customTypes/radio'
import moment from 'moment/moment'
import ChooseRadioData from '@containers/radio/ChooseRadioData'

interface Props {
  categoryId?: number | null
  hostId?: string | null
  onClickBack: () => void
}

const Posts: React.FC<Props> = ({ categoryId, hostId, onClickBack }) => {
  const [selectedRadioMessage, setSelectedRadioMessage] =
    useState<TypeRadioMessage>()
  const postRefs = useRef<(HTMLDivElement | null)[]>([])

  const { data: radioMessagesByCategory } = useGetRadioMessagesByCategory(
    categoryId?.toString() || '',
    {
      enabled: Boolean(categoryId),
    },
  )

  const { data: radioMessagesByHost } = useGetRadioMessagesByHost(
    hostId || '',
    {
      enabled: Boolean(hostId),
    },
  )

  const handleClickEdit = useCallback((radioMessage: TypeRadioMessage) => {
    setSelectedRadioMessage(radioMessage)
  }, [])

  useEffect(() => {
    telegramService.mainButton.hide()
    telegramService.backButton.show().onClick(onClickBack)
    return () => {
      telegramService.backButton.hide().offClick(onClickBack)
    }
  }, [onClickBack])

  const radioMessagesResponse = useMemo<
    TypeRadioMessagesResponse | undefined
  >(() => {
    return radioMessagesByCategory || radioMessagesByHost
  }, [radioMessagesByCategory, radioMessagesByHost])

  const sortedRadioMessages = useMemo<TypeRadioMessage[] | null>(() => {
    if (
      !radioMessagesResponse?.radioMessages ||
      radioMessagesResponse.radioMessages.length === 0
    ) {
      return null
    }
    return radioMessagesResponse.radioMessages.sort((a, b) => {
      const momentA = moment(a.botScheduler.nextPostDate)
      const momentB = moment(b.botScheduler.nextPostDate)
      return momentA.diff(momentB)
    })
  }, [radioMessagesResponse?.radioMessages])

  const handleClickBack = useCallback(() => {
    if (selectedRadioMessage) {
      const selectedRadioMessageId = selectedRadioMessage.id
      setSelectedRadioMessage(undefined)
      if (postRefs.current[selectedRadioMessageId]) {
        postRefs.current[selectedRadioMessageId]?.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
        })
      }
    }
  }, [selectedRadioMessage])

  if (selectedRadioMessage) {
    return (
      <ChooseRadioData
        botSchedulerId={selectedRadioMessage.botScheduler.id}
        onClickBack={handleClickBack}
      />
    )
  }

  if (!sortedRadioMessages) {
    return <p className='text-center'>{_t('noPostsForCategory')}</p>
  }

  return (
    <div className='flex flex-col gap-5 items-stretch secondary-bg h-full p-5'>
      {sortedRadioMessages.map(radioMessage => (
        <div
          ref={el => (postRefs.current[radioMessage.id] = el)}
          key={radioMessage.id}
        >
          <Post
            radioMessage={radioMessage}
            showEdit={!radioMessage.sendOnce}
            onClickEdit={() => handleClickEdit(radioMessage)}
          />
        </div>
      ))}
    </div>
  )
}

export default Posts
