import React, { useCallback, useMemo } from 'react'
import { useGetRadioCategories } from '@api/queries/radio/categories'
import { _t } from '@locales/index'
import {
  TypeRadioCategory,
  TypeRadioCategoryFormValues,
} from '@customTypes/radio'
import { Form, Formik } from 'formik'
import { radioCategoryValidationSchema } from '@containers/radio/utils'
import {
  RadioCategoryContentTypeEnum,
  RadioCategoryFormEnum,
} from '@enums/radio'
import UpdateCategoryInner from '@containers/radio/pages/UpdateCategoryInner'

interface Props {
  radioId: string
  onSubmit: () => void
  onClickViewPosts: () => void
  selectedCategoryId: number | null
}

const UpdateCategory: React.FC<Props> = ({
  radioId,
  onSubmit,
  onClickViewPosts,
  selectedCategoryId,
}) => {
  const { data: radioCategoriesResponse } = useGetRadioCategories(radioId, {
    enabled: Boolean(radioId),
  })

  const selectedCategory = useMemo<TypeRadioCategory | undefined>(() => {
    return radioCategoriesResponse?.radioCategories.find(
      category => category.id === selectedCategoryId,
    )
  }, [radioCategoriesResponse, selectedCategoryId])

  const handleSubmit = useCallback(
    (formValues: TypeRadioCategoryFormValues) => {
      console.log(formValues)
    },
    [],
  )

  const initialValues = useMemo<TypeRadioCategoryFormValues>(() => {
    return {
      [RadioCategoryFormEnum.Name]: selectedCategory?.name || '',
      [RadioCategoryFormEnum.SystemName]: selectedCategory?.sysName || '',
      [RadioCategoryFormEnum.ContentType]:
        selectedCategory?.contentType || RadioCategoryContentTypeEnum.Dynamic,
    }
  }, [selectedCategory])

  return (
    <div className='flex flex-col items-center gap-3 p-5'>
      <h1 className='h4 text-center'>{_t('updateCategoryFormTitle')}</h1>
      <div className='flex flex-wrap gap-2 items-center'></div>
      <Formik
        initialValues={initialValues}
        validationSchema={radioCategoryValidationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        <Form className={'w-full'}>
          <UpdateCategoryInner
            radioId={radioId}
            onSubmit={onSubmit}
            selectedCategory={selectedCategory}
            onClickViewPosts={onClickViewPosts}
          />
        </Form>
      </Formik>
    </div>
  )
}

export default UpdateCategory
