import React, { useEffect, useMemo, useState } from 'react'
import { MainRoutes } from '@routes/main'
import { useRoutes, useSearchParams } from 'react-router-dom'
import { Locale } from './core/locales'
import { LanguagesEnum } from '@enums/language'
import { RadioCategoriesContext } from '@context/radioCategories'
import { TypeRadioCategoriesContext } from '@customTypes/radio'
import { useGetRadioCategories } from '@api/queries/radio/categories'
import { arrayToObject } from '@common/index'
import useSaveUserTimezone from '@hooks/common/useSaveUserTimezone'

const App = () => {
  const [searchParams] = useSearchParams()
  const radioId = searchParams.get('radio_id') || ''
  useSaveUserTimezone()

  const [radioCategoriesContext, setRadioCategoriesContext] =
    useState<TypeRadioCategoriesContext>()

  const { data: radioCategoriesResponse } = useGetRadioCategories(radioId, {
    enabled: Boolean(radioId),
  })

  const lang: LanguagesEnum = useMemo(
    () => searchParams.get('lang') as LanguagesEnum,
    [searchParams],
  )

  useEffect(() => {
    if (lang) {
      Locale.setLanguage(lang)
    }
  }, [lang])

  useEffect(() => {
    if (radioCategoriesResponse) {
      const radioCategoriesMap = arrayToObject(
        radioCategoriesResponse.radioCategories,
      )
      setRadioCategoriesContext(radioCategoriesMap)
    }
  }, [radioCategoriesResponse])

  const mainRoutes = useRoutes(MainRoutes)

  return (
    <div className='App'>
      <RadioCategoriesContext.Provider value={radioCategoriesContext}>
        {mainRoutes}
      </RadioCategoriesContext.Provider>
    </div>
  )
}

export default App
