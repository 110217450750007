import { PopupButtonIdEnum } from '@enums/common'

declare const Telegram: any

class TelegramWebAppManager {
  webApp: any
  mainButton: any
  backButton: any

  constructor() {
    // TELEGRAM WEBAPP NATIVE CONFIG
    this.webApp = Telegram.WebApp

    this.mainButton = this.webApp.MainButton
    this.backButton = this.webApp.BackButton

    this.webApp.onEvent('themeChanged', this.setThemeClass.bind(this))

    this.webApp.setHeaderColor('bg_color')
    this.webApp.onEvent()

    this.setThemeClass()
  }

  getName() {
    return (
      this.webApp?.initDataUnsafe?.user?.first_name +
      ' ' +
      this.webApp?.initDataUnsafe?.user?.last_name
    )
  }

  setMainButtonText(text: string) {
    this.mainButton.setParams({
      text: text,
      visible: true,
    })
  }

  hapticFeedback(style: any) {
    this.webApp.HapticFeedback.impactOccurred(style)
  }

  expand() {
    this.webApp.expand()
  }

  close(disableClosingConfirmation = false) {
    if (disableClosingConfirmation) {
      this.webApp.disableClosingConfirmation()
    }
    this.webApp.close()
  }

  enableMainButton() {
    const body = document.documentElement
    this.mainButton.setParams({
      is_active: true,
      color:
        getComputedStyle(body).getPropertyValue('--tg-theme-button-color') ||
        'rgb(0, 0, 0)',
      text_color:
        getComputedStyle(body).getPropertyValue(
          '--tg-theme-button-text-color',
        ) || 'rgb(0, 0, 0)',
    })
  }

  disableMainButton() {
    const body = document.body
    this.mainButton.setParams({
      is_active: false,
      color:
        getComputedStyle(body).getPropertyValue('--disabled-button-bg') ||
        '#D3D3D3',
      text_color:
        getComputedStyle(body).getPropertyValue('--disabled-button-color') ||
        '#8C8C8C',
    })
  }

  setThemeClass() {
    document.documentElement.setAttribute('class', this.webApp.colorScheme)
  }

  showDeleteConfirmationPopup() {
    this.webApp.showPopup({
      title: 'Delete Host',
      message: 'Are you sure you want to delete?',
      buttons: [
        {
          type: 'cancel',
        },
        {
          id: PopupButtonIdEnum.Delete,
          type: 'destructive',
          text: 'Delete',
        },
      ],
    })
  }
}

export const telegramService = new TelegramWebAppManager()
