export enum ServerStateKeysEnum {
  MemberRequest = 'memberRequest',

  // hosts
  RadioHost = 'radioHost',
  RadioHosts = 'radioHosts',
  RadioMessageRelatedData = 'radioMessageRelatedData',
  RadioMessages = 'radioMessages',
  RadioScheduledMessages = 'radioScheduledMessages',
  RadioCategories = 'radioCategories',
}
