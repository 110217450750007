import React from 'react'
import { useGetMemberRequest } from '@api/queries/memberRequest'
import { useParams } from 'react-router-dom'

const Payment: React.FC = () => {
  const { token } = useParams()

  const { data: memberRequest } = useGetMemberRequest(token || '', {
    enabled: Boolean(token),
  })

  if (!memberRequest) {
    return null
  }

  return <section className='flex flex-col gap-12 summary-page'></section>
}

export default Payment
