import React, { useCallback } from 'react'
import { _t } from '@locales/index'
import { TypeRadioCategory } from '@customTypes/radio'

interface Props {
  label: string
  categories: TypeRadioCategory[] | null
  onClickEdit: () => void
}

const HostCategories: React.FC<Props> = ({
  label,
  categories,
  onClickEdit,
}) => {
  const handleClickBox = useCallback(() => {
    if (!categories || categories.length === 0) {
      onClickEdit()
    }
  }, [categories, onClickEdit])

  return (
    <div className='flex flex-col gap-2' onClick={handleClickBox}>
      <div className='flex items-center justify-between'>
        <p className='text-custom-teal font-semibold'>{label}</p>
        {categories && categories?.length > 0 && (
          <p
            className='text-base text-custom-radio-pink cursor-pointer'
            onClick={onClickEdit}
          >
            {_t('edit')}
          </p>
        )}
      </div>
      <div className='p-4 border rounded min-h-[60px]'>
        {categories && categories?.length > 0 ? (
          <div className='flex items-center flex-wrap gap-x-5'>
            {categories.map(category => (
              <p
                key={category.id}
                className='text-custom-radio-yellow text-[11px] uppercase font-bold'
              >
                {category.name}
              </p>
            ))}
          </div>
        ) : (
          <p className='text-base text-custom-radio-pink cursor-pointer capitalize text-center'>
            {_t('updateHostUpdateCategoriesLimit')}
          </p>
        )}
      </div>
    </div>
  )
}

export default HostCategories
