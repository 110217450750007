import React, { useCallback, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { TypeManageHostsScreen } from '@customTypes/radio'
import { ManageHostsScreenEnum } from '@enums/radio'
import { telegramService } from '@services/telegram/telegramService'
import UpdateHosts from '@containers/radio/pages/UpdateHosts'
import UpdateHost from '@containers/radio/pages/UpdateHost'
import Posts from '@containers/radio/pages/Posts'

const ManageHosts: React.FC = () => {
  const [searchParams] = useSearchParams()
  const radioId = searchParams.get('radio_id') || ''

  const [selectedHostId, setSelectedHostId] = useState<string | null>(null)
  const [currentScreen, setCurrentScreen] = useState<TypeManageHostsScreen>(
    ManageHostsScreenEnum.Hosts,
  )

  const handleSubmit = useCallback(() => {
    telegramService.close(true)
  }, [])

  const handleSelectHost = useCallback((hostId: string) => {
    setSelectedHostId(hostId)
    setCurrentScreen(ManageHostsScreenEnum.UpdateHost)
  }, [])

  const handleSubmitHost = useCallback(() => {
    setCurrentScreen(ManageHostsScreenEnum.Hosts)
    setSelectedHostId(null)
  }, [])

  const renderScreen = useMemo(() => {
    switch (currentScreen) {
      case ManageHostsScreenEnum.Hosts:
        return (
          <UpdateHosts
            radioId={radioId}
            onSubmit={handleSubmit}
            onSelectHost={handleSelectHost}
            onAddNew={() => setCurrentScreen(ManageHostsScreenEnum.UpdateHost)}
          />
        )
      case ManageHostsScreenEnum.UpdateHost:
        return (
          <UpdateHost
            selectedHostId={selectedHostId}
            onSubmit={handleSubmitHost}
            radioId={radioId}
            onClickViewPosts={() =>
              setCurrentScreen(ManageHostsScreenEnum.ViewPosts)
            }
          />
        )
      case ManageHostsScreenEnum.ViewPosts:
        return (
          <Posts
            hostId={selectedHostId}
            onClickBack={() =>
              setCurrentScreen(ManageHostsScreenEnum.UpdateHost)
            }
          />
        )
      default:
        return null
    }
  }, [
    currentScreen,
    handleSelectHost,
    handleSubmit,
    handleSubmitHost,
    radioId,
    selectedHostId,
  ])

  return <div className='flex flex-col h-screen w-screen'>{renderScreen}</div>
}

export default ManageHosts
