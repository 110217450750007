import React, { useCallback, useEffect } from 'react'
import { telegramService } from '@services/telegram/telegramService'
import { _t } from '@locales/index'
import {
  TypeCreateRadioCategoryPayload,
  TypeRadioCategory,
  TypeRadioCategoryContentType,
  TypeRadioCategoryFormValues,
  TypeUpdateRadioCategoryPayload,
} from '@customTypes/radio'
import { RadioCategoryFormEnum } from '@enums/radio'
import InputField from '@components/form/InputField'
import { RADIO_CATEGORY_CONTENT_TYPE_OPTIONS } from '@const/radio'
import { useFormikContext } from 'formik'
import {
  useCreateRadioCategory,
  useDeleteRadioCategory,
  useUpdateRadioCategory,
} from '@api/queries/radio/categories'
import { ReactComponent as CopyIcon } from '@assets/images/common/copy.svg'
import { ReactComponent as TrashIcon } from '@assets/images/common/trash.svg'
import SelectBox from '@components/form/SelectBox'
import { ServerStateKeysEnum } from '@enums/serverState'
import { useQueryClient } from 'react-query'
import { TypeClosePopupParams } from '@customTypes/common'
import { PopupButtonIdEnum } from '@enums/common'
import useCopyToClipboard from '@hooks/common/useCopyToClipboard'

interface Props {
  radioId: string
  selectedCategory?: TypeRadioCategory
  onSubmit: () => void
  onClickViewPosts: () => void
}

const UpdateCategoryInner: React.FC<Props> = ({
  selectedCategory,
  onSubmit,
  onClickViewPosts,
  radioId,
}) => {
  const queryClient = useQueryClient()
  const { copiedToClipboard, copyFn } = useCopyToClipboard()

  const { values, isValid, dirty } =
    useFormikContext<TypeRadioCategoryFormValues>()

  const { mutate: updateRadioCategory } = useUpdateRadioCategory()
  const { mutate: createRadioCategory } = useCreateRadioCategory()
  const { mutate: deleteRadioCategory } = useDeleteRadioCategory()

  const apiCallback = useCallback(() => {
    queryClient.invalidateQueries(ServerStateKeysEnum.RadioCategories).then()
    onSubmit()
  }, [onSubmit, queryClient])

  const mainButtonCallback = useCallback(() => {
    if (isValid) {
      if (selectedCategory) {
        const updatePayload: TypeUpdateRadioCategoryPayload = {
          id: selectedCategory.id.toString(),
          name: values[RadioCategoryFormEnum.Name],
          contentType: values[RadioCategoryFormEnum.ContentType],
        }

        if (values[RadioCategoryFormEnum.SystemName]) {
          updatePayload['sysName'] = values[RadioCategoryFormEnum.SystemName]
        }

        updateRadioCategory(updatePayload, {
          onSuccess: apiCallback,
        })
      } else {
        const createPayload: TypeCreateRadioCategoryPayload = {
          radioId: radioId,
          name: values[RadioCategoryFormEnum.Name],
          contentType: values[RadioCategoryFormEnum.ContentType],
        }

        if (values[RadioCategoryFormEnum.SystemName]) {
          createPayload['sysName'] = values[RadioCategoryFormEnum.SystemName]
        }

        createRadioCategory(createPayload, {
          onSuccess: apiCallback,
        })
      }
    }
  }, [
    apiCallback,
    createRadioCategory,
    isValid,
    radioId,
    selectedCategory,
    updateRadioCategory,
    values,
  ])

  const deleteAndGoBack = useCallback(() => {
    if (selectedCategory) {
      deleteRadioCategory(selectedCategory.id.toString(), {
        onSuccess: apiCallback,
      })
    }
  }, [selectedCategory, deleteRadioCategory, apiCallback])

  const handleClosePopup = useCallback(
    (params: TypeClosePopupParams) => {
      if (params.button_id === PopupButtonIdEnum.Delete) {
        deleteAndGoBack()
      }
    },
    [deleteAndGoBack],
  )

  const handleClickCopyLink = useCallback(() => {
    if (selectedCategory && selectedCategory.inviteLink) {
      copyFn(selectedCategory.inviteLink).then()
    }
  }, [copyFn, selectedCategory])

  const showConfirmationPopup = useCallback(() => {
    telegramService.showDeleteConfirmationPopup()
  }, [])

  useEffect(() => {
    telegramService.mainButton.show().onClick(mainButtonCallback)
    telegramService.backButton.show().onClick(onSubmit)
    telegramService.webApp.onEvent('popupClosed', handleClosePopup)
    return () => {
      telegramService.mainButton.offClick(mainButtonCallback)
      telegramService.backButton.hide().offClick(onSubmit)
      telegramService.webApp.offEvent('popupClosed', handleClosePopup)
    }
  }, [handleClosePopup, mainButtonCallback, onSubmit])

  useEffect(() => {
    if (selectedCategory) {
      telegramService.setMainButtonText(_t('update'))
    } else {
      telegramService.setMainButtonText(_t('addNew'))
    }
  }, [selectedCategory])

  useEffect(() => {
    if (isValid && dirty) {
      telegramService.enableMainButton()
    } else {
      telegramService.disableMainButton()
    }
  }, [dirty, isValid])

  return (
    <div className='flex flex-col gap-14'>
      <div className='flex flex-col gap-5'>
        <InputField
          label={_t('updateCategoryNameLabel') + '*'}
          name={RadioCategoryFormEnum.Name}
          id={RadioCategoryFormEnum.Name}
          fontWeightClass='font-normal'
        />
        <InputField
          label={_t('updateCategorySystemNameLabel')}
          name={RadioCategoryFormEnum.SystemName}
          id={RadioCategoryFormEnum.SystemName}
          fontWeightClass='font-normal'
        />
        <SelectBox<TypeRadioCategoryContentType>
          name={RadioCategoryFormEnum.ContentType}
          label={_t('updateCategoryContentTypeLabel') + '*'}
          options={RADIO_CATEGORY_CONTENT_TYPE_OPTIONS}
          fontClassName='font-normal'
        />
      </div>
      {selectedCategory && (
        <div className='flex flex-col gap-3'>
          <button className='btn btn-outlined' onClick={onClickViewPosts}>
            <p>{_t('showPostHistory')}</p>
          </button>
          <button className='btn btn-outlined' onClick={handleClickCopyLink}>
            <CopyIcon className='w-4 h-4' />
            <p>
              {copiedToClipboard
                ? _t('copiedToClipboard')
                : _t('copyInviteLink')}
            </p>
          </button>
          <button
            className='btn btn-outlined-red'
            onClick={showConfirmationPopup}
          >
            <TrashIcon className='fill-custom-error-red w-4 h-4' />
            <p>{_t('delete')}</p>
          </button>
        </div>
      )}
    </div>
  )
}

export default UpdateCategoryInner
