import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from 'react-query'
import { ServerStateKeysEnum } from '@enums/serverState'
import { botatoApiService } from '@services/api/apiService'
import { ApiKeyNameEnum } from '@enums/api'
import { AxiosError } from 'axios'
import {
  TypeCreateRadioCategoryPayload,
  TypeRadioCategoryResponse,
  TypeUpdateRadioCategoryPayload,
} from '@customTypes/radio'

/*****************************************
 Get All
 ****************************************/
type TypeGetRadioCategoriesOptions = UseQueryOptions<
  TypeRadioCategoryResponse,
  AxiosError,
  TypeRadioCategoryResponse,
  any
>

const getRadioCategories = async (
  radioId: string,
): Promise<TypeRadioCategoryResponse> => {
  try {
    const response = await botatoApiService.get(
      ApiKeyNameEnum.radio_categories,
      radioId + '/',
    )
    return response.data
  } catch (err) {
    throw err
  }
}

export const useGetRadioCategories = (
  radioId: string,
  options?: TypeGetRadioCategoriesOptions,
) => {
  return useQuery(
    [ServerStateKeysEnum.RadioCategories, radioId],
    () => getRadioCategories(radioId),
    {
      retry: 5,
      ...options,
    },
  )
}

/*****************************************
 Get By User
 ****************************************/
type TypeGetBotUserRadioCategoriesOptions = UseQueryOptions<
  TypeRadioCategoryResponse,
  AxiosError,
  TypeRadioCategoryResponse,
  any
>

const getBotUserRadioCategories = async (
  bot_user_id: string,
): Promise<TypeRadioCategoryResponse> => {
  try {
    const response = await botatoApiService.get(
      ApiKeyNameEnum.bot_user_radio_categories,
      bot_user_id + '/',
    )
    return response.data
  } catch (err) {
    throw err
  }
}

export const useGetBotUserRadioCategories = (
  bot_user_id: string,
  options?: TypeGetBotUserRadioCategoriesOptions,
) => {
  return useQuery(
    [ServerStateKeysEnum.RadioCategories, bot_user_id],
    () => getBotUserRadioCategories(bot_user_id),
    {
      retry: 5,
      ...options,
    },
  )
}

/*****************************************
 Create
 ****************************************/
type TypeCreateRadioCategoryOptions = UseMutationOptions<
  TypeCreateRadioCategoryPayload,
  AxiosError,
  TypeCreateRadioCategoryPayload
>

const createRadioCategory = async (
  values: TypeCreateRadioCategoryPayload,
): Promise<TypeCreateRadioCategoryPayload> => {
  try {
    const response = await botatoApiService.post(
      ApiKeyNameEnum.radio_category,
      values,
    )
    return response.data
  } catch (err) {
    throw err
  }
}

export const useCreateRadioCategory = (
  options?: TypeCreateRadioCategoryOptions,
) => {
  return useMutation(createRadioCategory, {
    ...options,
  })
}

/*****************************************
 Update
 ****************************************/
type TypeUpdateRadioCategoryOptions = UseMutationOptions<
  TypeUpdateRadioCategoryPayload,
  AxiosError,
  TypeUpdateRadioCategoryPayload
>

const updateRadioCategory = async (
  values: TypeUpdateRadioCategoryPayload,
): Promise<TypeUpdateRadioCategoryPayload> => {
  try {
    const id = values.id || ''
    delete values.id
    const response = await botatoApiService.putUrl(
      ApiKeyNameEnum.radio_category,
      id + '/',
      values,
    )
    return response.data
  } catch (err) {
    throw err
  }
}

export const useUpdateRadioCategory = (
  options?: TypeUpdateRadioCategoryOptions,
) => {
  return useMutation(updateRadioCategory, {
    ...options,
  })
}

/*****************************************
 Delete
 ****************************************/
type TypeDeleteRadioCategoryOptions = UseMutationOptions<
  string,
  AxiosError,
  any
>

const deleteRadioCategory = async (
  radioCategoryId: string,
): Promise<string> => {
  try {
    const response = await botatoApiService.deleteUrl(
      ApiKeyNameEnum.radio_category,
      radioCategoryId + '/',
    )
    return response.data
  } catch (err) {
    throw err
  }
}

export const useDeleteRadioCategory = (
  options?: TypeDeleteRadioCategoryOptions,
) => {
  return useMutation(deleteRadioCategory, {
    ...options,
  })
}
