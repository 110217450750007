import React, { useCallback } from 'react'
import { useDeleteRadioMessage } from '@api/queries/radio/messages'
import { _t } from '@locales/index'
import { ReactComponent as TrashIcon } from '@assets/images/common/trash.svg'
import { TypeRadioMessage } from '@customTypes/radio'
import moment from 'moment/moment'
import { useQueryClient } from 'react-query'
import { telegramService } from '@services/telegram/telegramService'
import { TypeClosePopupParams } from '@customTypes/common'
import { PopupButtonIdEnum } from '@enums/common'
import { ServerStateKeysEnum } from '@enums/serverState'
import Message from '@containers/radio/components/Message'

interface Props {
  radioMessage: TypeRadioMessage
  showEdit: boolean
  onClickEdit?: (radioMessage: TypeRadioMessage) => void
}

const Post: React.FC<Props> = ({
  radioMessage,
  showEdit,
  onClickEdit = () => {},
}) => {
  const queryClient = useQueryClient()

  const { mutate: deleteRadioMessage } = useDeleteRadioMessage()

  const handleClosePopup = useCallback(
    (params: TypeClosePopupParams) => {
      if (params.button_id === PopupButtonIdEnum.Delete) {
        deleteRadioMessage(radioMessage.id.toString(), {
          onSuccess: () => {
            queryClient
              .invalidateQueries(ServerStateKeysEnum.RadioMessages)
              .then()
            queryClient
              .invalidateQueries(ServerStateKeysEnum.RadioCategories)
              .then()
            queryClient.invalidateQueries(ServerStateKeysEnum.RadioHosts).then()
          },
        })
      }
      telegramService.webApp.offEvent('popupClosed', handleClosePopup)
    },
    [deleteRadioMessage, queryClient, radioMessage.id],
  )

  const showDeleteConfirmation = useCallback(() => {
    telegramService.showDeleteConfirmationPopup()
    telegramService.webApp.onEvent('popupClosed', handleClosePopup)
  }, [handleClosePopup])

  const handleClickEditPost = useCallback(() => {
    onClickEdit(radioMessage)
  }, [onClickEdit, radioMessage])

  const message = radioMessage.botScheduler.message
  const sendDate = moment.utc(radioMessage.botScheduler.nextPostDate).local()
  const isSent = sendDate.isBefore(moment())

  return (
    <div className='flex flex-col flex-shrink-0 border gap-3 rounded-md p-3 main-bg overflow-hidden'>
      {Array.isArray(message) ? (
        message.map((msg, i) => <Message key={i} message={msg} />)
      ) : (
        <Message message={message} />
      )}
      <div className='flex flex-col gap-3'>
        <div className='bg-text-color h-[0.5px]' />
        {radioMessage.botScheduler.nextPostDate ? (
          <div className='flex justify-between items-center'>
            <p className='body3'>
              {isSent ? _t('sentAt') : _t('scheduledFor')}
            </p>
            <p className='caption'>
              {sendDate.format('Do MMMM, YYYY [at] hh:mm A')}
            </p>
          </div>
        ) : (
          <p className='body3'>{_t('partOfQueue')}</p>
        )}
      </div>
      {showEdit && (
        <button className='btn btn-outlined' onClick={handleClickEditPost}>
          <p>{_t('editCategoriesAndHosts')}</p>
        </button>
      )}
      <button className='btn btn-outlined-red' onClick={showDeleteConfirmation}>
        <TrashIcon className='fill-custom-error-red w-4 h-4' />
        <p className='text-custom-error-red'>{_t('delete')}</p>
      </button>
    </div>
  )
}

export default Post
