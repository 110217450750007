import React, { useState } from 'react'
import classNames from 'classnames'

export interface AvatarProps {
  imageUrl?: string
  name?: string
  widthClass?: string
  textSizeClass?: string
  borderClass?: string
}

const Avatar: React.FC<AvatarProps> = ({
  imageUrl,
  widthClass,
  name,
  textSizeClass,
  borderClass,
}) => {
  const [hasLoadingError, setHasLoadingError] = useState<boolean>(false)

  return (
    <div
      className={classNames(
        'rounded-xl overflow-hidden bg-custom-gray-2 flex justify-center items-center bg-center bg-cover',
        widthClass || 'w-20 h-24',
        borderClass,
      )}
      style={{
        backgroundImage: imageUrl ? `url('${imageUrl}')` : undefined,
      }}
    >
      {imageUrl && !hasLoadingError && (
        <img
          src={imageUrl || ''}
          alt='user'
          className={'w-full h-full hidden'}
          onError={() => setHasLoadingError(true)}
          onLoad={() => setHasLoadingError(false)}
        />
      )}
      {(!imageUrl || hasLoadingError) && name && (
        <p
          className={classNames(
            'text-white font-poppins text-800 font-semibold uppercase',
            textSizeClass || 'text-2xl',
          )}
        >
          {name.substring(0, 1)}
        </p>
      )}
    </div>
  )
}

export default Avatar
