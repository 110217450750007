import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from 'react-query'
import { ServerStateKeysEnum } from '@enums/serverState'
import { botatoApiService } from '@services/api/apiService'
import { ApiKeyNameEnum } from '@enums/api'
import { AxiosError } from 'axios'
import {
  TypeCreateRadioHostPayload,
  TypeGetRadioHostByBotUserPayload,
  TypeGetRadioHostsParams,
  TypeRadioHost,
  TypeRadioHostsResponse,
  TypeUpdateRadioHostPayload,
} from '@customTypes/radio'
import queryString from 'query-string'
import snakecaseKeys from 'snakecase-keys'

/*****************************************
 Get Radio Host
 ****************************************/
type TypeGetRadioHostOptions = UseQueryOptions<
  TypeRadioHost,
  AxiosError,
  TypeRadioHost,
  any
>

const getRadioHost = async (radio_host_id: string): Promise<TypeRadioHost> => {
  try {
    const response = await botatoApiService.get(
      ApiKeyNameEnum.radio_host,
      radio_host_id + '/',
    )
    return response.data
  } catch (err) {
    throw err
  }
}

export const useGetRadioHost = (
  radio_host_id: string,
  options?: TypeGetRadioHostOptions,
) => {
  return useQuery(
    [ServerStateKeysEnum.RadioHost, radio_host_id],
    () => getRadioHost(radio_host_id),
    {
      retry: 5,
      ...options,
    },
  )
}

/*****************************************
 Get Radio Host By Bot User
 ****************************************/
type TypeGetRadioHostByBotUserOptions = UseQueryOptions<
  TypeRadioHost,
  AxiosError,
  TypeRadioHost,
  any
>

const getRadioHostByBotUser = async (
  payload: TypeGetRadioHostByBotUserPayload,
): Promise<TypeRadioHost> => {
  try {
    const { radioId, botUserId } = payload
    const response = await botatoApiService.get(
      ApiKeyNameEnum.radio_host,
      `${radioId}/${botUserId}`,
    )
    return response.data
  } catch (err) {
    throw err
  }
}

export const useGetRadioHostByBotUser = (
  payload: TypeGetRadioHostByBotUserPayload,
  options?: TypeGetRadioHostByBotUserOptions,
) => {
  return useQuery(
    [ServerStateKeysEnum.RadioHost, payload],
    () => getRadioHostByBotUser(payload),
    {
      retry: 5,
      ...options,
    },
  )
}

/*****************************************
 Get All
 ****************************************/
type TypeGetRadioHostsOptions = UseQueryOptions<
  TypeRadioHostsResponse,
  AxiosError,
  TypeRadioHostsResponse,
  any
>

const getRadioHosts = async (
  params: TypeGetRadioHostsParams,
): Promise<TypeRadioHostsResponse> => {
  try {
    const { radioId, ...otherParams } = params
    const qs = queryString.stringify(snakecaseKeys(otherParams), {
      arrayFormat: 'comma',
      skipNull: true,
      skipEmptyString: true,
    })
    const response = await botatoApiService.get(
      ApiKeyNameEnum.radio_hosts,
      `${radioId}/?${qs}`,
    )
    return response.data
  } catch (err) {
    throw err
  }
}

export const useGetRadioHosts = (
  params: TypeGetRadioHostsParams,
  options?: TypeGetRadioHostsOptions,
) => {
  return useQuery(
    [ServerStateKeysEnum.RadioHosts, params],
    () => getRadioHosts(params),
    {
      retry: 5,
      ...options,
    },
  )
}

/*****************************************
 Get By User
 ****************************************/
type TypeGetBotUserRadioHostsOptions = UseQueryOptions<
  TypeRadioHostsResponse,
  AxiosError,
  TypeRadioHostsResponse,
  any
>

const getBotUserRadioHosts = async (
  bot_user_id: string,
): Promise<TypeRadioHostsResponse> => {
  try {
    const response = await botatoApiService.get(
      ApiKeyNameEnum.bot_user_radio_hosts,
      bot_user_id + '/',
    )
    return response.data
  } catch (err) {
    throw err
  }
}

export const useGetBotUserRadioHosts = (
  bot_user_id: string,
  options?: TypeGetBotUserRadioHostsOptions,
) => {
  return useQuery(
    [ServerStateKeysEnum.RadioHosts, bot_user_id],
    () => getBotUserRadioHosts(bot_user_id),
    {
      retry: 5,
      ...options,
    },
  )
}

/*****************************************
 Create
 ****************************************/
type TypeCreateHostOptions = UseMutationOptions<
  TypeCreateRadioHostPayload,
  AxiosError,
  TypeCreateRadioHostPayload
>

const createRadioHost = async (
  values: TypeCreateRadioHostPayload,
): Promise<TypeCreateRadioHostPayload> => {
  try {
    const response = await botatoApiService.post(
      ApiKeyNameEnum.radio_host,
      values,
    )
    return response.data
  } catch (err) {
    throw err
  }
}

export const useCreateRadioHost = (options?: TypeCreateHostOptions) => {
  return useMutation(createRadioHost, {
    ...options,
  })
}

/*****************************************
 Update
 ****************************************/
type TypeUpdateRadioHostOptions = UseMutationOptions<
  TypeUpdateRadioHostPayload,
  AxiosError,
  TypeUpdateRadioHostPayload
>

const updateRadioHost = async (
  values: TypeUpdateRadioHostPayload,
): Promise<TypeUpdateRadioHostPayload> => {
  try {
    const id = values.id || ''
    delete values.id
    const response = await botatoApiService.putUrl(
      ApiKeyNameEnum.radio_host,
      id + '/',
      values,
    )
    return response.data
  } catch (err) {
    throw err
  }
}

export const useUpdateRadioHost = (options?: TypeUpdateRadioHostOptions) => {
  return useMutation(updateRadioHost, {
    ...options,
  })
}

/*****************************************
 Delete
 ****************************************/
type TypeDeleteRadioHostOptions = UseMutationOptions<string, AxiosError, any>

const deleteRadioHost = async (radioHostId: string): Promise<string> => {
  try {
    const response = await botatoApiService.deleteUrl(
      ApiKeyNameEnum.radio_host,
      radioHostId + '/',
    )
    return response.data
  } catch (err) {
    throw err
  }
}

export const useDeleteRadioHost = (options?: TypeDeleteRadioHostOptions) => {
  return useMutation(deleteRadioHost, {
    ...options,
  })
}
