import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import {
  TypeBaseRadioHost,
  TypeChooseHostsScriptParams,
} from '@customTypes/radio'
import RadioHosts from '@containers/radio/pages/RadioHosts'
import { useStartBotScript } from '@api/queries/botScript'
import { TypeStartBotScriptPayload } from '@customTypes/botScript'
import { telegramService } from '@services/telegram/telegramService'
import {
  useGetBotUserRadioHosts,
  useGetRadioHosts,
} from '@api/queries/radio/hosts'
import { _t } from '@locales/index'
import { useGetBotUserRadioCategories } from '@api/queries/radio/categories'
import { RadioUserTypeEnum } from '@enums/radio'

const ChooseHosts: React.FC = () => {
  const [searchParams] = useSearchParams()
  const radioId = searchParams.get('radio_id') || ''
  const botUserId = searchParams.get('bot_user_id') || ''

  const { mutate: startBotScript } =
    useStartBotScript<TypeChooseHostsScriptParams>()

  const [selectedHostIds, setSelectedHostIds] = useState<string[]>([])
  const [userHostIds, setUserHostIds] = useState<string[]>([])

  const { data: botUserRadioHostsResponse } = useGetBotUserRadioHosts(
    botUserId,
    {
      enabled: Boolean(botUserId),
    },
  )

  const { data: botUserRadioCategoriesResponse } = useGetBotUserRadioCategories(
    botUserId,
    {
      enabled: Boolean(botUserId),
    },
  )

  const { data: radioHostsResponse } = useGetRadioHosts(
    { radioId, visibleToConsumers: true },
    {
      enabled: Boolean(radioId),
    },
  )

  const selectedRadioHosts = useMemo<TypeBaseRadioHost[] | null>(() => {
    if (
      !radioHostsResponse ||
      !selectedHostIds ||
      selectedHostIds.length === 0
    ) {
      return null
    }

    return radioHostsResponse.radioHosts.filter(host =>
      selectedHostIds.includes(host.id),
    )
  }, [radioHostsResponse, selectedHostIds])

  const handleSelectHost = useCallback((hostId: string) => {
    setSelectedHostIds(prevHostIds => [...prevHostIds, hostId])
  }, [])

  const handleUnselectHost = useCallback((hostId: string) => {
    setSelectedHostIds(prevHostIds => prevHostIds.filter(id => id !== hostId))
  }, [])

  const handleSubmit = useCallback(() => {
    const radioSelectedHostNames =
      selectedRadioHosts?.map(host => host.name).join(',') || _t('noHosts')

    const payload: TypeStartBotScriptPayload<TypeChooseHostsScriptParams> = {
      botId: searchParams.get('bot_id') || '',
      userId: searchParams.get('user_id') || '',
      scriptId: searchParams.get('next_script_id') || '',
      step: parseInt(searchParams.get('next_script_step') || '1'),
      params: {
        radio_selected_host_ids: selectedHostIds,
        radio_selected_host_names: radioSelectedHostNames,
      },
    }

    startBotScript(payload, {
      onSuccess: async () => {
        telegramService.close(true)
      },
    })
  }, [searchParams, selectedHostIds, selectedRadioHosts, startBotScript])

  useEffect(() => {
    if (botUserRadioHostsResponse) {
      const botUserHostIds = botUserRadioHostsResponse.radioHosts.map(
        host => host.id,
      )
      setUserHostIds(botUserHostIds)
      setSelectedHostIds(botUserHostIds)
    }
  }, [botUserRadioHostsResponse])

  const selectedCategoryIds = useMemo<number[]>(() => {
    if (!botUserRadioCategoriesResponse) {
      return []
    }
    return botUserRadioCategoriesResponse.radioCategories.map(
      category => category.id,
    )
  }, [botUserRadioCategoriesResponse])

  return (
    <div className='flex flex-col p-5'>
      <RadioHosts
        radioId={radioId}
        userHostIds={userHostIds}
        selectedHostIds={selectedHostIds}
        onSelectHost={handleSelectHost}
        onUnselectHost={handleUnselectHost}
        onClickNext={handleSubmit}
        selectedCategoryIds={selectedCategoryIds}
        userType={RadioUserTypeEnum.Listener}
      />
    </div>
  )
}

export default ChooseHosts
