export const PATH = {
  // root
  root: '/',

  // payment
  payment: '/payment/telegram/:token',

  // radio
  radioSelectData: '/radio/select/',
  radioSelectCategories: '/radio/select/categories',
  radioSelectHosts: '/radio/select/hosts',
  radioManageHosts: '/radio/hosts/manage',
  radioHostScheduledPosts: '/radio/host/scheduled-posts',
  radioManageCategories: '/radio/categories/manage',

  // No match
  noMatch: '*',
}
