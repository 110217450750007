import { TypeOptions } from '@customTypes/common'

const numFormatLookup = [
  { value: 1, symbol: '' },
  { value: 1e3, symbol: 'k' },
  { value: 1e6, symbol: 'M' },
  {
    value: 1e9,
    symbol: 'G',
  },
  { value: 1e12, symbol: 'T' },
  { value: 1e15, symbol: 'P' },
  { value: 1e18, symbol: 'E' },
]

export const numFormatter = (num: number, digits: number = 2): string => {
  const rx = /\.0+$|(\.\d*[1-9])0+$/
  const item = numFormatLookup
    .slice()
    .reverse()
    .find(function (i) {
      return num >= i.value
    })
  return item
    ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol
    : '0'
}

export const isNonZeroFalsy = (value: any): boolean => {
  return !value && value !== 0 && value !== -0
}

export const createTypeOptions = <T>(
  objects: Record<string, any>[],
  labelKey: string,
  valueKey: string,
): TypeOptions<T> => {
  return objects.map(obj => ({
    label: String(obj[labelKey]),
    value: obj[valueKey],
  }))
}

export const arrayToObject = <T extends Record<K, any>, K extends keyof any>(
  array: T[],
  keyName: K = 'id' as K,
): Record<T[K], T> => {
  return array.reduce((obj, item) => {
    const keyValue = item[keyName]
    obj[keyValue] = item
    return obj
  }, {} as Record<T[K], T>)
}
