export enum ApiKeyNameEnum {
  member_request = 'member_request',

  // radio categories
  radio_category = 'radio_category',
  radio_categories = 'radio_categories',
  bot_user_radio_categories = 'bot_user_radio_categories',

  // radio messages
  radio_message = 'radio_message',

  // radio hosts
  radio_host = 'radio_host',
  radio_hosts = 'radio_hosts',
  bot_user_radio_hosts = 'bot_user_radio_hosts',

  start_bot_script = 'start_bot_script',
  bot_user = 'bot_user',

  // media
  pre_upload_media = 'pre_upload_media',
  set_media_public = 'set_media_public',
}

export enum ResponseStatusCodeEnum {
  ObjectDoesNotExist = 204,
}
