import { TypeApiKeys } from '@customTypes/api'
import { ApiKeyNameEnum } from '@enums/api'

export const apiKeys: TypeApiKeys = {
  [ApiKeyNameEnum.member_request]: '/entity/request/',

  // radio categories
  [ApiKeyNameEnum.radio_category]: '/radio/category/',
  [ApiKeyNameEnum.radio_categories]: '/radio/categories/',
  [ApiKeyNameEnum.bot_user_radio_categories]: '/radio/categories/user/',

  // radio hosts
  [ApiKeyNameEnum.radio_host]: '/radio/host/',
  [ApiKeyNameEnum.radio_hosts]: '/radio/hosts/',
  [ApiKeyNameEnum.bot_user_radio_hosts]: '/radio/hosts/user/',

  // radio messages
  [ApiKeyNameEnum.radio_message]: '/radio/message/',

  // bot scripts
  [ApiKeyNameEnum.start_bot_script]: '/bot_user/start_script/',
  [ApiKeyNameEnum.bot_user]: '/bot_user/',

  // media
  [ApiKeyNameEnum.pre_upload_media]: '/media/pre_upload/',
  [ApiKeyNameEnum.set_media_public]: '/media/set_public/',
}
