import React, { Fragment, useMemo } from 'react'
import { ErrorMessage, useField } from 'formik'
import { Listbox, Transition } from '@headlessui/react'
import { ReactComponent as PointerIcon } from '@assets/images/common/down-pointer.svg'
import SelectBoxOption from '@components/form/SelectBoxOption'
import classNames from 'classnames'
import { TypeOption, TypeOptions } from '@customTypes/common'

interface Props<T> {
  name: string
  label: string
  options: TypeOptions<T>
  multi?: boolean
  buttonClassName?: string
  fontClassName?: string
  labelClass?: string
  listOptionsClassName?: string
  checkbox?: string
  onClickListBox?: () => void
  hideErrorMessage?: boolean
}

const SelectBox = <T,>({
  label,
  options,
  name,
  multi = false,
  buttonClassName,
  fontClassName,
  labelClass,
  listOptionsClassName,
  checkbox,
  onClickListBox,
  hideErrorMessage,
}: Props<T>) => {
  const [, meta, helpers] = useField<string[]>(name)
  const [, singleMeta, singleHelpers] = useField<string>(name)

  const hasValue = useMemo<boolean>(() => {
    return Boolean(meta?.value?.length > 0 || singleMeta?.value?.length > 0)
  }, [meta, singleMeta])

  const selectedOption = useMemo<TypeOption<T> | undefined>(() => {
    return options.find(option => option.value === meta.value)
  }, [meta.value, options])

  return (
    <div className='flex flex-col w-full'>
      {label && (
        <label
          htmlFor={name}
          className={classNames('body2 capitalize', labelClass)}
        >
          {label}
        </label>
      )}
      <Listbox
        value={multi ? meta.value : singleMeta.value}
        onChange={multi ? helpers.setValue : singleHelpers.setValue}
        multiple={multi}
      >
        {({ open }) => (
          <div className='relative mt-1 z-[9]'>
            <div onClick={onClickListBox}>
              <Listbox.Button
                className={
                  buttonClassName
                    ? buttonClassName
                    : 'relative w-full h-full rounded border bg-theme flex items-center justify-between py-1.5 px-3 gap-5'
                }
              >
                <span
                  className={classNames(
                    'font-poppins text-sm capitalize',
                    hasValue && 'font-bold',
                    fontClassName && fontClassName,
                  )}
                >
                  {selectedOption?.label || label}
                </span>
                <PointerIcon className={'w-6 h-6'} />
              </Listbox.Button>
            </div>
            <Transition
              show={open}
              as={Fragment}
              enter='transition ease-out duration-200'
              enterFrom='opacity-0 translate-y-1'
              enterTo='opacity-100 translate-y-0'
              leave='transition ease-in duration-150'
              leaveFrom='opacity-100 translate-y-0'
              leaveTo='opacity-0 translate-y-1'
            >
              <Listbox.Options
                static
                className={
                  listOptionsClassName
                    ? listOptionsClassName
                    : 'absolute mt-1 w-auto min-w-full overflow-auto rounded bg-theme shadow-lg focus:outline-none border divide-y divide-custom-gray-5 z-100'
                }
              >
                {options.map((option, idx) => (
                  <SelectBoxOption<T>
                    option={option}
                    key={idx}
                    checkbox={checkbox}
                  />
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        )}
      </Listbox>
      {!hideErrorMessage && (
        <ErrorMessage
          name={name}
          component={'p'}
          className='text-custom-error-red caption capitalize mr-auto mt-2'
        />
      )}
    </div>
  )
}

export default SelectBox
