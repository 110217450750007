import { useState } from 'react'

type CopiedValue = string | null
type CopyFn = (text: string) => Promise<boolean>

interface CopyProps {
  copiedText: CopiedValue
  copiedToClipboard: boolean
  copyFn: CopyFn
}

const useCopyToClipboard = (): CopyProps => {
  const [copiedText, setCopiedText] = useState<CopiedValue>(null)
  const [copiedToClipboard, setCopiedToClipboard] = useState<boolean>(false)

  const copy: CopyFn = async text => {
    if (!navigator?.clipboard) {
      console.warn('Clipboard not supported')
      return false
    }

    // Try to save to clipboard then save it in the state if worked
    try {
      await navigator.clipboard.writeText(text)
      setCopiedText(text)
      setCopiedToClipboard(true)
      setTimeout(() => {
        setCopiedToClipboard(false)
      }, 2 * 1000)
      return true
    } catch (error) {
      console.warn('Copy failed', error)
      setCopiedText(null)
      return false
    }
  }

  return {
    copiedText,
    copiedToClipboard,
    copyFn: copy,
  }
}

export default useCopyToClipboard
